import * as React from 'react';
import * as _ from 'lodash';
import '../CSS/MainApp.css';
import {AppFooter} from './Headers';
import {
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import {Spinner, SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import { IMainAppProps, IMainApprState, IAccountInfo, ISystemPermissions, IUserRole, IPermissions, IMaxDate } from './IMainApp';
import  AppRoute  from '../Main/AppRoute';
import CacheManager from '../services/CacheManager';
import {getAuthenticatedClient} from '../services/graph-service';
import SystemAdminService from '../services/systemadmin-service';
import { RouteComponentProps } from 'react-router-dom';
import { IFilterData } from '../Components/ActivityGrid/ActivityGridFilters';
import AppConfig from '../Constants';
import App from '../App';


export const UserInfoContext = React.createContext({name:'',userName:'',isAdmin:false,Roles:[] as number[],RolePermissions:[] as string[],SysPermissions:[] as IPermissions[],MaxDate:AppConfig.ActivityForm.MaxDate});
export const ActivityGridContext = React.createContext<any>({}as any);
export class MainApp extends React.Component<RouteComponentProps & IMainAppProps,IMainApprState>  {
    constructor(props:RouteComponentProps & IMainAppProps) {
        super(props);
        this.state = {
            selectedKey:'0',
            systemAdmins:[],
            isLoaded: true,
            accountInfo:{} as IAccountInfo,
            permissions:[],
            errorMessage:'',
            isError:false
        };
    }    
    getAccessToken = async () => {
        await getAuthenticatedClient();
    }
    componentDidMount() {
        const {accountInfo} = this.props; 
        this.setState({isLoaded:false,accountInfo});
        const promisesArr = [];
        CacheManager.createCacheItem('accountInfo',
        JSON.stringify(accountInfo),0.5);       
        this.getAccessToken();
        promisesArr.push(this.getUserRoles(accountInfo.account.userName));
        promisesArr.push(this.getSystemRolePermissions());
        
        Promise.all(promisesArr)
        .then(responses => {
            if (responses.length > 0) {
                let userRoleArr:IUserRole[] = responses[0] ? responses[0] : [];
                let systemPermisssions:ISystemPermissions[] = responses[1];
                // let maxDateArr:IMaxDate[] = responses[2];
                let activeSysPermissions:ISystemPermissions[] = _.filter(systemPermisssions,{StatusId: AppConfig.ActivityForm.ActiveStatusId})
                //console.log("userRoleArr",userRoleArr);
                //console.log("activeSysPermissions",activeSysPermissions); 
                let permissionsObjArr:IPermissions[] = this.formPermissionsObject(activeSysPermissions);
                let userRoles:number[] = userRoleArr.length>0?_.map(userRoleArr,"RoleId") :[];
                //console.log("userRoles",userRoles); 
                let sysAdminId:number = process.env.REACT_APP_ADMIN_ID?parseInt(process.env.REACT_APP_ADMIN_ID):0;
                let isAdminFlag:boolean = userRoles.indexOf(sysAdminId) !== -1;
                //console.log("isAdminFlag",isAdminFlag); 
                accountInfo.account.isAdmin = isAdminFlag;
                accountInfo.account.Roles = userRoles;
                accountInfo.account.SysPermissions = permissionsObjArr;
                accountInfo.account.RolePermissions = this.getCurrUserRolePermissions(permissionsObjArr,userRoles);
                // accountInfo.account.MaxDate = maxDateArr?.length>0?maxDateArr[0].MaxDate:AppConfig.ActivityForm.MaxDate;
                this.setState({
                    permissions:permissionsObjArr,
                    accountInfo:accountInfo,
                    isLoaded:true,
                    isError:false
                });
            }   
        })
        .catch(error => {
            accountInfo.account.Roles = [];         
            accountInfo.account.SysPermissions = [];  
            this.setState({
                accountInfo:accountInfo,
                isLoaded:true,
                isError:true,
                errorMessage:"System Permissions retrival error. Please retry by refreshing page."
            })
            console.log(error);
        });
    } 
    getCurrUserRolePermissions = (allRolePermissions:IPermissions[],RoleIdArr:number[]):string[] =>{
        var rolePermisssionsArr: string[] = [];
        for (let roleIndex = 0; roleIndex < RoleIdArr.length; roleIndex++) {
            const eachRoleId:number = RoleIdArr[roleIndex];
            let _permissions:IPermissions[] = _.filter(allRolePermissions,{RoleId:eachRoleId});
            if(_permissions && _permissions.length>0){
                rolePermisssionsArr.push(..._permissions[0].RolePermissions);
            }              
        }
        //console.log(rolePermisssionsArr);
        return rolePermisssionsArr;
    }
    getSystemRolePermissions = () :Promise<ISystemPermissions[]|any>=> {
        return new Promise((resolve, reject) => {
            SystemAdminService.getSystemRolePermissions()
            .then((systemAdmins:ISystemPermissions[]) => resolve(systemAdmins))
            .catch(error => {
                reject(error);
            });
        });
    } 
    formPermissionsObject = (sysPermissions:ISystemPermissions[]):IPermissions[]=>{
        var permissionsArr:IPermissions[] =[];
        var groupSysPerm:any = _.groupBy(sysPermissions,"RoleId");
        var keysArr:string[] = Object.keys(groupSysPerm);
        for (let pIndex = 0; pIndex < keysArr.length; pIndex++) {
            let permissions:IPermissions = {} as IPermissions;
            const eachRoleId:string = keysArr[pIndex];
            const eachSysPermGrp:ISystemPermissions[] = groupSysPerm[eachRoleId];
            permissions.RoleId = eachSysPermGrp[0].RoleId;
            permissions.RoleName = eachSysPermGrp[0].RoleName;
            permissions.RolePermissions = _.map(eachSysPermGrp,'RolePermission');
            permissionsArr.push({...permissions});            
        }
        //console.log("permissionsArr",permissionsArr);
        return permissionsArr;
    }   
    getUserRoles = (userName:string) :Promise<IUserRole[]|any>=> {
        return new Promise((resolve, reject) => {
            SystemAdminService.getUserRoles(userName)
            .then((userRoles:IUserRole[]) => resolve(userRoles))
            .catch(error => {
                reject(error);
            });
        });
    }   
    render() {
        const {selectedKey, accountInfo, isError, isLoaded,errorMessage} = this.state;
        return (
            <div className="App">
                <div className="HeaderSection">
               { isError &&(<MessageBar messageBarType={MessageBarType.error} style={{margin:"0px"}}>
                    {errorMessage}
                    </MessageBar>)}
                </div>
                <div className="AppBody">
                    <ActivityGridContext.Provider value={{} as any}>                    
                        <UserInfoContext.Provider value={accountInfo.account}>
                            {/* <AppRoute  {...this.props} selectedKey={selectedKey}  accountInfo={accountInfo}/> */}
                            {isLoaded? <AppRoute  {...this.props} selectedKey={selectedKey}  accountInfo={accountInfo}/>:
                            (
                                <div className="centeredContainer">
                                    <Spinner size={SpinnerSize.large} />
                                </div>
                            )}
                        </UserInfoContext.Provider>
                    </ActivityGridContext.Provider>
                </div>
                {isLoaded &&<AppFooter />}
            </div>
        );
    }
}

