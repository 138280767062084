import * as React from 'react';
import {TextField, ITextFieldStyles,ITextFieldStyleProps} from 'office-ui-fabric-react/lib/TextField';
import {Spinner,SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import {DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
import { IActivitySync, IEditOutLookFromProps, IEditOutLookFromState, IEditOutlootEvent, INewOutlookItem, IOutlookBody, IOutlookDateTime, IOutlootEvent, IShiftTimings } from '../interfaces/IOutlookSync';
import { SyncService } from './SyncService';
import { MessageBar, MessageBarType } from '@fluentui/react';
import AppConfig from '../../../Constants';
import { eachRight } from 'lodash';
 


// const uuidv4 = require('uuid/v4');


const txtFieldClass:Partial<ITextFieldStyleProps> & Partial<ITextFieldStyles> = {
    wrapper: {
        width: '100%',
        display: 'block',
        selectors:{
            'disabled':{
                color:'black'
            }
        }
    },
    root: {
        width: '100%',
    },
    subComponentStyles:{
        label:{
            color:'black!important'
        }
    }
};
export default class EditOutLookFrom extends React.Component<IEditOutLookFromProps, IEditOutLookFromState> {
  constructor(props: IEditOutLookFromProps) {
    super(props);

    this.state = {
        OutLookItem:{
          Subject:"",
          Body:"",
          LocationsStr:"",
          TransactionId:"",
          ShiftTimings:[] as IShiftTimings[],
          ShiftTimingsClubbed:[] as IShiftTimings[]
      },
      isSaving:false,
      isRemoving:false,
      showMsg:false,
      Msg:"",
      MsgType:MessageBarType.info
    }
  }
  componentDidMount =() =>{
    const {ActivitySyncData}:any= this.props;
    let olObj:INewOutlookItem = this.formOutLookItem(ActivitySyncData)
    this.setState({
      OutLookItem:olObj
    });
  }
  componentDidUpdate = (prevProps:IEditOutLookFromProps, prevState: IEditOutLookFromState) =>{
    const {ActivitySyncData}:any= this.props;
    if(prevProps.ActivitySyncData.length !== ActivitySyncData.length){
      let olObj:INewOutlookItem = this.formOutLookItem(ActivitySyncData)
      this.setState({
        OutLookItem:olObj
      });
    }
  }
  formOutLookItem(ActivitySyncData: IActivitySync[]): INewOutlookItem {
      var olObj:INewOutlookItem =  {} as INewOutlookItem; 
      if(ActivitySyncData.length>0){
        olObj.Body = ActivitySyncData[0].EventBody;
        olObj.Subject = ActivitySyncData[0].EventTitle;
      }
      return olObj;
  }
  private checkIfDeleteResponseIsSuccess = (calRes:any) =>{
    try {
      for (let index = 0; index < calRes.length; index++) {
        const eachRes = calRes[index];
        if (eachRes.Status.toString() !== "204" && eachRes.Status.toString() !== "404") {
          return false;
        } 
      }
    } catch (error) {
      return false;
    }          
    return true;
  }  
  private handleRemoveBtnClick = () =>{
    const {ActivitySyncData} = this.props;
    this.setState({isRemoving:true});
    let actId:number = ActivitySyncData[0].ActivityId; 
    let eventSyncedBy:string = ActivitySyncData[0].EventSyncedBy;
    SyncService.DeleteOutlookEvent(ActivitySyncData).then((calRes:any) =>{
      if(this.checkIfDeleteResponseIsSuccess(calRes)){
        SyncService.purgeActivitySyncRecord(actId,eventSyncedBy).then(actSyncRecord => {
          console.log(actSyncRecord);
          this.setState({isRemoving:false});
          this.props.handleActSyncSave(ActivitySyncData,"Delete");
        })
        .catch((error:any) => {
          console.log(error);
          this.setState({
            isRemoving:false,
            showMsg:true,
            Msg:"Remove failed. Please try later.",
            MsgType:MessageBarType.error
          });
        });
      }
      else{
        this.setState({
          isRemoving:false,
          showMsg:true,
          Msg:"Remove failed. Please try later.",
          MsgType:MessageBarType.error
        });
      }
    }).catch((err: any) => {
         this.setState({
                isRemoving:false,
                showMsg:true,
                Msg:"Remove failed. Please try later.",
                MsgType:MessageBarType.error
            });
        console.log(err);        
    });
  }
  private handleOnTitleChange = (ev:any) =>{
    let val:string = ev.target.value;
    const {OutLookItem}:any = this.state;
    OutLookItem.Subject = val;
    this.setState({
        OutLookItem
    });
  } 
  private handleOnDescChange = (ev:any) =>{
      let val:string = ev.target.value;
      const {OutLookItem}:any = this.state;
      OutLookItem.Body = val;
      this.setState({
          OutLookItem
      });
  }
  private handleCancelBtnClick = () =>{
    this.props.handleFormCancelClick();
  }
  private generateCalEvents(OutLookItem: INewOutlookItem): IEditOutlootEvent[] {
    let eventArr:IEditOutlootEvent[] = [] as IEditOutlootEvent[]; 
    let itemURL:string = "<p><a href='"+ window.location.origin + "/PlanActivity?id="+ this.props.ActivityDetails.Id+"'>Click here<a/> to navigate to the activity in SWFT application</p>" ;
    var defaultTxt:string = itemURL + AppConfig.OutLook.DefaultBodyAppendMsg;
    console.log(defaultTxt);
    let events:IActivitySync[] = this.props.ActivitySyncData
    for (let index = 0; index < events.length; index++) {
        const event = events[index];
        let editObj:IEditOutlootEvent = {} as IEditOutlootEvent;
        let obj:IOutlootEvent = {} as IOutlootEvent;
        obj.body = {} as IOutlookBody;
        obj.body.contentType = "HTML";
        obj.body.content = OutLookItem.Body + defaultTxt;
        obj.subject = OutLookItem.Subject;
        editObj.Body =  {...obj}; 
        editObj.EventId =  event.EventId; 
        eventArr.push({...editObj});
    }
    return eventArr;
  }
  private formUpdateActSyncObj(calResponse: any): any {
    let actSyncObj:IActivitySync = {} as IActivitySync;
    const {OutLookItem} = this.state;
    if(calResponse){
        try {
            actSyncObj.ActivityId = this.props.ActivityDetails.Id?this.props.ActivityDetails.Id:0;
            actSyncObj.EventId = calResponse.response.id;
            actSyncObj.EventTitle = calResponse.response.subject;
            actSyncObj.EventBody = OutLookItem.Body;
            actSyncObj.EventSyncedBy = calResponse.response.organizer.emailAddress.address;
            actSyncObj.StatusId = 1;
            return actSyncObj;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    return false;
  }
  private checkIfUpdateResponseIsSuccess = (calRes:any) =>{
    try {
      for (let index = 0; index < calRes.length; index++) {
        const eachRes = calRes[index];
        if (eachRes.Status.toString() !== "200") {
          return false;
        } 
      }
    } catch (error) {
      return false;
    }          
    return true;
  }
  updateActSyncArr(actSyncObj: IActivitySync): IActivitySync[] {
    let actSyncArr:IActivitySync[] = [];
    const{ActivitySyncData} = this.props;
    for (let index = 0; index < ActivitySyncData.length; index++) {
      const eachSync:IActivitySync = ActivitySyncData[index];
      eachSync.EventBody = actSyncObj.EventBody;
      eachSync.EventTitle = actSyncObj.EventTitle;
      actSyncArr.push({...eachSync});
    }
    return actSyncArr;
  }
  private handlePushBtnClick = () =>{
    const {OutLookItem} = this.state;
    if(!OutLookItem.Subject){
      this.setState({
          showMsg:true,
          Msg:"Please fill the required fields.",
          MsgType:MessageBarType.error
      });
    }
    else{
      this.callForPush(OutLookItem);
    }
  }
  callForPush = (OutLookItem:INewOutlookItem)=>{
    this.setState({isSaving:true,showMsg:false});
    let calendarDetailsArr:IEditOutlootEvent[] = this.generateCalEvents(OutLookItem);      
    SyncService.UpdateOutlookEvent(calendarDetailsArr).then((calRes:any)=>{
      console.log(calRes);
      if(this.checkIfUpdateResponseIsSuccess(calRes)){
          let actSyncObj:IActivitySync = this.formUpdateActSyncObj(calRes[0]);
          if(actSyncObj){
              SyncService.updateActivitySyncRecord(actSyncObj).then((actSyncRecord:any) => {
                this.setState({isSaving:false});
                let actSyncArr:IActivitySync[] = this.updateActSyncArr(actSyncObj);
                this.props.handleActSyncSave(actSyncArr,"Update");
              })
              .catch(error => {
                  console.log(error);
                  this.setState({
                      isSaving:false,
                      showMsg:true,
                      Msg:"Push to Outlook failed. Please try later.",
                      MsgType:MessageBarType.error
                  });
              });
          }
      }else{
          console.log(calRes);
          this.setState({
              isSaving:false,
              showMsg:true,
              Msg:"Push to Outlook failed. Please try later.",
              MsgType:MessageBarType.error
          });
      }
    }).catch((err: any) => {
        console.log(err);
        this.setState({
            isSaving:false,
            showMsg:true,
            Msg:"Push to Outlook failed. Please try later.",
            MsgType:MessageBarType.error
        });
    });
  } 
  private renderMsgbar(Msg: string, MsgType: MessageBarType) {
    return(
        <MessageBar messageBarType={MsgType} className={"errorMsgInfo"}>
            {Msg}
        </MessageBar>
    );
  }
  public render() {
    const {OutLookItem,isSaving,isRemoving,showMsg,Msg,MsgType} = this.state;
    const turnOffSync = {iconName:"RemoveEvent"};//styles:{root:{color:"red"}}
    return (
      <>
        <TextField
                label="Event Subject"
                required
                name="Subject"
                placeholder="Event Subject"
                value={OutLookItem.Subject}
                errorMessage={!OutLookItem.Subject? "Event Subject is required filed.":''}
                onChange={this.handleOnTitleChange}
                styles={txtFieldClass}/>
          <TextField
              label="Event Description"
              multiline={true}
              name="Body"
              placeholder="Event Description"
              value={OutLookItem.Body}
              onChange={this.handleOnDescChange}
              styles={txtFieldClass}/>
          {showMsg && (this.renderMsgbar(Msg,MsgType))}
          <div style={{marginTop:20,display:"flex",justifyContent:"space-between"}}>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  {isSaving &&( 
                    <Spinner
                      label={"Saving"}
                      ariaLive="assertive"
                      labelPosition="left"
                      size={SpinnerSize.small}/>
                  )}
                  <PrimaryButton text="Push" disabled={showMsg} iconProps={{iconName:"Save"}}onClick={this.handlePushBtnClick}  style={{margin:"0 5px"}}/>
                  <DefaultButton text="Cancel" iconProps={{iconName:"Cancel"}} onClick={this.handleCancelBtnClick} />
              </div>   
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  {isRemoving &&( 
                      <Spinner
                          label={"Removing"}
                          ariaLive="assertive"
                          labelPosition="left"
                          size={SpinnerSize.small}
                      />
                    )}
                  <PrimaryButton iconProps={turnOffSync} disabled={showMsg} text="Remove from Outlook" onClick={this.handleRemoveBtnClick}  style={{marginLeft:5}}/>
              </div>
          </div>
      </>
    );
  }
}
