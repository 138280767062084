import { DefaultButton, Dropdown, Icon, IDropdownOption, Label, Link, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import AppConfig from '../../Constants';
import { UserInfoContext } from '../../Main';
import ActivitySyncService from '../../services/activity-sync-service';
import { showActControl, RoleVerbs } from '../../services/PermissionsService';
import ActionScreen from '../Common/ActionScreen';
import { BlockingSpinner } from '../Common/dialogs/BlockingSpinner';
import ButtonWithDialog from '../Common/dialogs/ButtonWithDialog';

import { IProject } from '../Experiment/interfaces/IExperiment';
import FormWrapper from '../FormWrapper';
import ActivityDateOffset from '../Activity/ActivityDateOffset';
import { cloneActivity, deleteActivityRecordByID, formatActivityDate, formatNonTimeDate, getActivityByID, getDropDownOptions, getLocationEquipments, getProjectData, getShiftData, getUniqueLocationObjs, today, updateActivity, validateEmail } from '../Activity/ActivityService';
import { IActivity, IActivityEquipment, IActivityShift, IActvityUserRole, IUserPersonaField } from '../Activity/interfaces/IActivityForm';
import { ILocationShiftAvailability } from '../Activity/interfaces/ILocationShiftAvailability';
import { IActivitySync } from '../Activity/interfaces/IOutlookSync';
import LocationsAndEquipments from '../Activity/LocationsAndEquipments';
import LocationShiftAvailability from '../Activity/LocationShiftAvailability';
import OutlookSyncForm from '../Activity/OutLookEventSync/OutlookSyncForm';
import { SyncService } from '../Activity/OutLookEventSync/SyncService';
import PersonVisitor from '../Activity/PersonVisitor';
import { IEditOfficeSpaceProps, IEditOfficeSpaceState } from './interfaces/IOfficeSpace';

const moment =  require('moment');
const uuidv4 = require('uuid/v4');


const descFieldClass = {
    wrappetr: {
        width: '100%',
        display: 'block',
    },
    root: {
        width: '100%',
    },
    field: {
        height: 200,
    },
};
let InitialErrorMsg = {
    activityDate: '',
    selectedShiftIds: '',
    activityName: '',
    activityDescription: '',
    bookedForEmail: '',
    visitorEmail: '',
    selectedLocations: '',
    activityNotes:''
};
let InitialActivity = {
    ExperimentId:null,
    DayOffset:0,
    ProjectId:0,
    activityDate: new Date(formatActivityDate()),
    selectedShiftIds: [],
    activityName: '',
    activityDescription: '',
    bookedForEmail: [] as IUserPersonaField[],
    bookedByEmail: {} as IUserPersonaField,
    visitorEmail: '',
    selectedLocations: [],
    selectedEquipments: [],
    showVisitorField: false
};
export default class EditOfficeSpace extends React.Component<IEditOfficeSpaceProps, IEditOfficeSpaceState> {
  constructor(props: IEditOfficeSpaceProps) {
    super(props);
    EditOfficeSpace.contextType = UserInfoContext;
    this.state = {
        editOfficeSpaceId:0,
        editOfficeSpace: {
            ExperimentId:null,
            ProjectId:0,
            DayOffset:0,
            activityDate: new Date(formatActivityDate()),
            selectedShiftIds: [],
            activityName: '',
            activityDescription: '',
            bookedForEmail: [] as IUserPersonaField[],
            bookedByEmail: {} as IUserPersonaField,
            visitorEmail: '',
            selectedLocations: [],
            selectedEquipments: [],
            showVisitorField: false,
        },
        InitialShifts:[] as IActivityShift[], 
        LocationEquipmentData: [] as IActivityEquipment[],
        Projects:[],
        ShiftTimmings:[],
        Locations: [],
        Equipments: [],
        selectedFor:[],
        LsaArr:[] as ILocationShiftAvailability[],
        formFieldKey:uuidv4(),
        locationEquipKey:uuidv4(),
        errorMsgs:InitialErrorMsg,
        errorMsg:'',
        LSAFullyBooked:false,
        viewForm:false,
        showErrorMsg:false,
        isLoaded:false,
        isTBDSelected:false,
        ActivityNotSaved:false,
        canEdit: true,
        ShowSyncBtn:false,
        hideDeleteDialog:true,
        hideUpdateDialog:true,
        hideCancelDialog:true,
        hideCloneDialog:true,
        SaveFlag: false,
        isUpdated: false,
        isRedirect: false,
        isCreated: false,
        isDeleted: false,
        isClone:false,
        IsRecordEditable:false,// checks is past, or inactive
        ActSyncData:[] as IActivitySync[],
        ActSyncMsg: "",
        activityNotes: "",
        ActUserRole:"None",
        actionType:"Update",
    }
  }
  private projectsDropDownOptions: IDropdownOption[] = [];
  private initialEditActivity: IActivity = {} as IActivity;
   
  componentDidMount = () => {
    const promises = [];
    promises.push(getShiftData());
    promises.push(getLocationEquipments());
    promises.push(getProjectData());
    promises.push(getActivityByID(this.props.Id));
    promises.push(SyncService.getActivitySyncForUser(this.props.Id,this.context?.userName));
    Promise.all(promises).then((responses:any) => {
        this.setState({isLoaded:true});
            if (responses.length > 0) {
                let shifts:IActivityShift[] = responses[0] ? _.filter(responses[0],{StatusId:AppConfig.ActivityForm.ActiveStatusId}) : [];
                let locationEquipmentData:IActivityEquipment[] = responses[1]? responses[1]: [];
                let projectData:IProject[] = responses[2]? responses[2]: [];
                let currActivityData:any = responses[3]? responses[3]: [];
                let actSyncData:IActivitySync[] = responses[4]? responses[4]: [];
                this.arrangeFormFields(shifts, locationEquipmentData, projectData, currActivityData, actSyncData);
            }
            else {
                this.setState({
                    isLoaded:true,
                    showErrorMsg: true,
                    errorMsg: AppConfig.ActivityForm.ErrorDataFetchMessage
                });
            }
        })
        .catch(error => {
            this.setState({
                isLoaded:true,
                showErrorMsg: true,
                errorMsg: AppConfig.ActivityForm.ErrorDataFetchMessage
            });
            console.log(error);
        });
    }
    componentDidUpdate(prevProps:IEditOfficeSpaceProps, prevState:IEditOfficeSpaceState) {
        const {Id} = this.props;
        if(prevProps.Id!==Id){
            getActivityByID(this.props.Id).then((response)=>{
                if (response > 0) {
                    let currActivityData:any = response? response: [];
                    const {LocationEquipmentData,InitialShifts,Projects,ActSyncData} = this.state;
                    this.arrangeFormFields(InitialShifts, LocationEquipmentData, currActivityData,Projects,ActSyncData);
                } else {
                        this.setState({
                            editOfficeSpaceId:this.props.Id,
                            isLoaded:true,
                            showErrorMsg: true,
                            errorMsg: AppConfig.ActivityForm.ErrorDataFetchMessage
                        });
                }
            });
        }
    }
    arrangeFormFields = (shifts:IActivityShift[], locationEquipmentData:IActivityEquipment[],projectData:IProject[],currActivityData:any,actSyncData:IActivitySync[]) => {
        let shiftsArr:IActivityShift[] = this.prepareShiftsData(shifts);
        let uniqLocationsArr = getUniqueLocationObjs(locationEquipmentData, this.props.office);
        this.projectsDropDownOptions = getDropDownOptions(projectData,'Name');

        let editOfficeSpace:IActivity = this.getActivityDetailData(currActivityData);        
        this.initialEditActivity = JSON.parse(JSON.stringify(editOfficeSpace));
        let activityShifts = this.getShiftsSelection(shiftsArr,editOfficeSpace.selectedShiftIds);
        let canEdit:boolean = false;
        let IsRecordEditable:boolean = false;
        let ShowSyncBtn:boolean = false;
        let canUserEdit:IActvityUserRole= this.checkIfUserAdmiOrEditor(editOfficeSpace);
        if(canUserEdit.isEditor){
            var isEditable:boolean = this.checkIfRecordIsEditable(editOfficeSpace);
            canEdit = isEditable && showActControl(this.context,canUserEdit.UserRole,RoleVerbs.Edit);
            IsRecordEditable = isEditable;
            ShowSyncBtn = isEditable;
        }
        else{
            ShowSyncBtn = this.checkIfRecordIsEditable(editOfficeSpace);
            canEdit = canUserEdit.isEditor;
        }
        let selectedFor =  editOfficeSpace.showVisitorField?[]:editOfficeSpace.bookedForEmail;
        const {viewForm} = this.props;
        let disableProjectIfExp:boolean = false;
        
        let isViewForm:boolean = false;
        if(canUserEdit.UserRole === "Admin"){
            isViewForm = viewForm? viewForm: false;
        }else{
            isViewForm = viewForm || !canEdit;
        }
        this.setState({
            editOfficeSpaceId:this.props.Id,
            editOfficeSpace: editOfficeSpace,
            InitialShifts:shifts,
            ShiftTimmings: activityShifts,
            Projects:projectData,
            Locations: uniqLocationsArr,
            Equipments:locationEquipmentData,
            isLoaded: true,
            locationEquipKey: uuidv4(),
            selectedFor,
            isTBDSelected:this.isTBDSelected(editOfficeSpace.selectedShiftIds),
            ActSyncData:this.getActiveSync(actSyncData),
            LocationEquipmentData: locationEquipmentData,           
            canEdit,
            IsRecordEditable,
            ShowSyncBtn,
            viewForm: isViewForm,
            ActUserRole:canUserEdit.UserRole
        });        
    }
    prepareShiftsData = (shifts:IActivityShift[]):IActivityShift[] =>{
        var shiftArr:IActivityShift[] = [];
        if (!AppConfig.ActivityForm.showTBD) {
            shifts = _.reject(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
            shifts.map(element => {
                return (element.isSelected = false);
            });
            shiftArr = shifts;
        } else {
            //arrange TBD to be first place
            let tbdShiftArr = _.filter(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
            if (tbdShiftArr && tbdShiftArr.length > 0) {
                let tbdShift = tbdShiftArr[0];
                tbdShift.isSelected = false;
                shifts = _.reject(shifts, {DisplayName: AppConfig.ActivityForm.TBDRejectDispName});
                shiftArr.push(tbdShift);
                for (let sndex = 0; sndex < shifts.length; sndex++) {
                    const shiftElm = shifts[sndex];
                    shiftElm.isSelected = false;
                    shiftArr.push(shiftElm);
                }
            } else {
                for (let sndex = 0; sndex < shifts.length; sndex++) {
                    const shiftElm = shifts[sndex];
                    shiftElm.isSelected = false;
                    shiftArr.push(shiftElm);
                }
            }
        }
        return shiftArr;
    }
    getShiftsSelection(shiftArr: IActivityShift[], selectedShiftIds: number[]) {
        for (let shiftIndex = 0; shiftIndex < shiftArr.length; shiftIndex++) {
            const shiftRecord = shiftArr[shiftIndex];
            if(selectedShiftIds.indexOf(shiftRecord.Id)!== -1){
                shiftRecord.isSelected = true;
            }else{
                shiftRecord.isSelected = false;
            }            
        }
        return shiftArr;
    }
    checkExpDateIsPast = (expDate:any) =>{
        var currExpDate = moment(expDate);
        var currDate = moment(today);
        var diffDates = currDate.diff(currExpDate,'days');
        if(expDate && diffDates>0){
            return new Date(formatActivityDate());
        }
        else{
            return expDate;
        }
    }
    isTBDSelected = (selectedShifts:number[]):boolean =>{
        if(selectedShifts.indexOf(AppConfig.ActivityForm.TBDRejectShiftId) !==-1){
            return true;
        }
        return false;
    }
    getActivityDetailData(currActivityData: any): IActivity {
        var editOfficeSpace:IActivity = {...InitialActivity};
        try {
            var activityDetails = currActivityData.ActivytData[0];
            editOfficeSpace.Id = activityDetails.ActivityId;
            var actDate = formatNonTimeDate(activityDetails.ActivityDate);
            editOfficeSpace.activityDate = actDate?new Date(actDate):null;
            editOfficeSpace.activityName = activityDetails.ActivityName;
            editOfficeSpace.activityDescription = activityDetails.ActivityDescription;
            editOfficeSpace.showVisitorField = !activityDetails.IsEmployee;
            editOfficeSpace.ProjectId = activityDetails.ActivityProjectId;
            if(activityDetails.IsEmployee){
                let obj:IUserPersonaField = {} as IUserPersonaField;
                obj.DisplayName = activityDetails.BookedForName;
                obj.Email = activityDetails.BookedForEmail;
                editOfficeSpace.bookedForEmail = [];
                editOfficeSpace.bookedForEmail.push(obj);
            }else{
                editOfficeSpace.showVisitorField = true;
                editOfficeSpace.visitorEmail = activityDetails.BookedForEmail
            }
            let obj:IUserPersonaField = {} as IUserPersonaField;
                obj.DisplayName = activityDetails.BookedByName;
                obj.Email = activityDetails.BookedByEmail;
                editOfficeSpace.bookedByEmail = obj;
            
            editOfficeSpace.DayOffset = activityDetails.DayOffset;
            
            editOfficeSpace.selectedShiftIds = currActivityData.ActivityShift;
            editOfficeSpace.selectedLocations = currActivityData.ActivityLocation;
            editOfficeSpace.selectedEquipments = currActivityData.ActivityEquipment;
            //experiment Data
            editOfficeSpace.ExperimentId = activityDetails.ExperimentId;
            editOfficeSpace.ExperimentOwner = activityDetails.ExperimentOwner;
            editOfficeSpace.ExperimentStatusId = activityDetails.ExperimentStatusId;
            var expDate = formatNonTimeDate(activityDetails.ExperimentStartDate);
            editOfficeSpace.ExperimentStartDate = expDate?new Date(expDate):null;
            editOfficeSpace.ActivityStatus = activityDetails.ActivityStatus;            
        } 
        catch (error) {
            console.log(error);
        }
        return editOfficeSpace;
    }
    checkIfUserAdmiOrEditor = (editOfficeSpace: IActivity):IActvityUserRole=> {
        var userRole:IActvityUserRole = {} as IActvityUserRole;
        console.log(this.context);
        userRole.UserRole = "None";
           try {
            if(this.context?.isAdmin){
                userRole.isEditor = true;
                userRole.UserRole = "Admin";                
            }
            else{                            
                    //individual Activity
                if(editOfficeSpace.bookedByEmail.Email.toUpperCase() === this.context?.userName.toUpperCase()){
                    userRole.isEditor = true;
                    userRole.UserRole = "Actowner";  
                }
                if(!editOfficeSpace.showVisitorField && 
                    editOfficeSpace.bookedForEmail[0].Email.toUpperCase() === this.context?.userName.toUpperCase()){
                    userRole.isEditor = true;
                    userRole.UserRole = "Actowner";
                }
            }
           } catch (error) {
               console.log("checkIfUserAdmiOrEditor", error);
           }
            console.log("Can Edit", userRole.isEditor);
        return userRole;
    } 
    checkIfRecordIsEditable = (editOfficeSpace: IActivity):boolean=> {
        var isEditable:boolean = true;
        var currActivityDate = moment(editOfficeSpace.activityDate);
        var currDate = moment(today);
        var diffDates = currDate.diff(currActivityDate,'days');
        var officeLocId = AppConfig.ActivityForm.officeSpaceUsageLocation;
        try {
            if(editOfficeSpace.ActivityStatus === AppConfig.ActivityForm.InactiveStatusId){
                isEditable = false;
            }
            if(editOfficeSpace.activityDate && diffDates>0){
                isEditable = false;
            }
            if(editOfficeSpace.selectedEquipments.length>0){
                isEditable = false;
            }
            if(editOfficeSpace.selectedLocations.length>1){
                isEditable = false;
            }
            if(editOfficeSpace.selectedLocations[0] !== officeLocId ){
                isEditable = false;
            }
        } catch (error) {
            console.log("checkIfRecordIsEditable", error);            
        }
            console.log("isEditable", isEditable);
        return isEditable;
    } 
    getActiveSync(actSyncData: IActivitySync[]): IActivitySync[] {
        let filteredObj:IActivitySync[] = [] as IActivitySync[];
        filteredObj =  _.filter(actSyncData,{StatusId:1});
        if(filteredObj && filteredObj.length>0){
            return filteredObj;
        }
        return [] as IActivitySync[];
    }


    handleActDateChange = (updateProp:any, field:string) =>{
        const {editOfficeSpace} :any = this.state;
        editOfficeSpace[field] = updateProp;
        this.setState({
            editOfficeSpace,
            showErrorMsg: false,
            ActivityNotSaved:true,
            errorMsg: ''
        }); 
    }
    handleTextFieldsChange  = (event:any,newValue:any) =>{
        const {editOfficeSpace} :any = this.state;
        let fieldName:any = event.target.name;
        let value:any = newValue;
        if(value.trim() === '') {
            editOfficeSpace[fieldName] = '';
        } else {
            editOfficeSpace[fieldName] = value;
        }
        this.setState({editOfficeSpace});
    }
    handleProjectChange  = (event:any,option:IDropdownOption|undefined) =>{
        const {editOfficeSpace} = this.state;
        if(option){
            editOfficeSpace.ProjectId = parseInt(option.key.toString());
            this.setState({ActivityNotSaved:true,editOfficeSpace});
        }
    }
    handlePersonVisitorChange = (eventObj:any) => {
        const {editOfficeSpace,LsaArr} :any = this.state, val = eventObj.target.value;
        if(eventObj.target.name === 'visitorEmail'){
            let rasieAlert = this.alertLSAFullyBooked(LsaArr,val);
            editOfficeSpace[eventObj.target.name] = val;
            this.setState({
                editOfficeSpace,
                ActivityNotSaved:true,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else if(eventObj.target.name === "bookedForEmail"){
            let rasieAlert = val.length>0?this.alertLSAFullyBooked(LsaArr,val[0].Email):false;
            editOfficeSpace[eventObj.target.name] = val;
            this.setState({
                editOfficeSpace,
                ActivityNotSaved:true,
                selectedFor:eventObj.target.selectedFor,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else if(eventObj.target.name === "showVisitorField"){
            let rasieAlert = this.alertLSAFullyBooked(LsaArr,val);
            editOfficeSpace[eventObj.target.name] = val;
            // editOfficeSpace.bookedForEmail = [];
            editOfficeSpace.visitorEmail = '';
            this.setState({
                editOfficeSpace,
                ActivityNotSaved:true,
                showErrorMsg: rasieAlert,
                errorMsg: rasieAlert? AppConfig.ActivityForm.FullyBookedAlertMsg:''
            });
        }
        else{
            this.setState({
                editOfficeSpace: editOfficeSpace,
                ActivityNotSaved:true
            });
        }
    }
    handleEquipmentSelection = (updateProp:any, field:string) => {
        let editOfficeSpace:any= this.state.editOfficeSpace;
        editOfficeSpace[field] = updateProp;
        this.setState({
            editOfficeSpace,
            ActivityNotSaved:true
        });
    }
    handleLocationSelection = (locationValues:any, equipValues:string) => {
        const {editOfficeSpace,LsaArr} :any = this.state;
        editOfficeSpace['selectedLocations'] = locationValues;
        editOfficeSpace['selectedEquipments'] = equipValues;
        var rasieAlert = this.alertLSAFullyBooked(LsaArr);
        this.setState({
            editOfficeSpace,
            showErrorMsg: rasieAlert,
            ActivityNotSaved:true,
            errorMsg: rasieAlert?AppConfig.ActivityForm.FullyBookedAlertMsg:''
        });
    }
    handleShiftChange = (event:any) =>{
        var options:any = event.target.options;
        const {editOfficeSpace, LsaArr} :any = this.state;
        var values:number[]  = this.getOptionsSelected(options);
        var rasieAlert = this.alertLSAFullyBooked(LsaArr);

        editOfficeSpace.selectedShiftIds = values;
        this.setState({
            editOfficeSpace,
            showErrorMsg:rasieAlert,
            errorMsg: rasieAlert?AppConfig.ActivityForm.FullyBookedAlertMsg:''
        });
    }
    handleMultiSelectClear = (event:any) => {
        let fieldName:any = event.target.name;
        const {editOfficeSpace} = this.state;
        if (fieldName  === "Shift") {
            editOfficeSpace.selectedShiftIds = [];
            this.setState({editOfficeSpace});
        }
    }
    updateStateLSA = (lsaArr:any) => {
        var raiseError = this.alertLSAFullyBooked(lsaArr);
        const{showErrorMsg} = this.state;
        this.setState({
            LsaArr:lsaArr,
            showErrorMsg:showErrorMsg?showErrorMsg:raiseError,
            errorMsg: AppConfig.ActivityForm.FullyBookedAlertMsg
        });
    }


    renderErrorMsgForField(msg:string,key:any){
        return(
            <div role="alert" key={key}>
                <p className="ms-TextField-errorMessage alertMsg">
                    <span data-automation-id="error-message">{msg}</span>
                </p>
            </div>
        );
    }
    renderErrorMsg(msg:string){
        return(
            <MessageBar messageBarType={MessageBarType.error} className={"errorMsgInfo"}>
                {msg}
            </MessageBar>
        );
    }
    renderCloneInfoMsg(){
        const {CloneTime} = this.props;
        let currTime = new Date().getTime();
        let type = "Office Usage"; 
        let msg = type +" is clonned successfully. You can update the details of the cloned"+ type +" here.";
        if( CloneTime && currTime<CloneTime){
            return(
                <MessageBar messageBarType={MessageBarType.success} className={"errorMsgInfo"}>
                    {msg}
                </MessageBar>
            );
        }
        return '';
    }
    renderActSyncMsg = (ActSyncMsg:string)  =>{
        if(ActSyncMsg === "Push"){
            return(
                <MessageBar messageBarType={MessageBarType.success} className={"errorMsgInfo"}>
                    Office Usage added to your outlook calendar successfully.
                </MessageBar>
            );
        }
        if(ActSyncMsg === "Error"){
            return(
                <MessageBar messageBarType={MessageBarType.error} className={"errorMsgInfo"}>
                    Office Usage addition to your outlook calendar failed. Please try after sometime.
                </MessageBar>
            );
        }
        if(ActSyncMsg === "Delete"){
            return(
                <MessageBar messageBarType={MessageBarType.success} className={"errorMsgInfo"}>
                    Deleted office usage event from outlook calendar.
                </MessageBar>
            );
        } 
        if(ActSyncMsg === "Update"){
            return(
                <MessageBar messageBarType={MessageBarType.success} className={"errorMsgInfo"}>
                    Updated office usage event in your outlook calendar.
                </MessageBar>
            );
        }
    }
    loadSpinner = (labelTxt:string,isLoading:boolean) => {
        return (
            <div className="centeredContainer">
                <BlockingSpinner label={labelTxt} hideDialog={!isLoading}/>
            </div>
        );
    }
    getRedirectURI = (office:boolean|undefined) =>{
        const {editOfficeSpace, editOfficeSpaceId} = this.state;
        let CurrTimePlus2 =  new Date(new Date().getTime() + 1*60000).getTime();
        let cloneParams = '&ParentId='+editOfficeSpace.Id + '&CloneTime='+CurrTimePlus2;
        let url = '/PlanOfficeSpace?id='+editOfficeSpaceId+cloneParams;
        return url;
    }
    handleActionAndRediret(): JSX.Element {
        const {
            isCreated,
            isUpdated,
            isDeleted,
            actionType,
            editOfficeSpaceId,
            canEdit,
            isClone,
            viewForm,
            ActUserRole,         
        } = this.state;
        const {office} = this.props;
        const subText =  'Office Usage';
        const redirectURL ='/PlanOfficeSpace?Id='+editOfficeSpaceId;
        const redirectURI = this.getRedirectURI(office);
        return(
            <div>
                {isUpdated?(
                        <ActionScreen 
                            subText= {subText+' updated. Redirecting to edit page...'}
                            dialogTitle= {'Updated Successfully'}
                            actionBtnTxt= {'go Home'}
                            showModal= {isUpdated}
                            isBlocking={true}
                            actionType ={actionType}
                            shouldDefaultRedirect= {true}
                            defaulltRedirectURI= {redirectURL}
                            timmer={AppConfig.ActivityForm.FormRedirectionTimmer}
                        />
                ):''}
                {isDeleted?(
                        <ActionScreen 
                            subText= {subText+' Deleted. Redirecting to Home page...'}
                            dialogTitle= {'Deleted Successfully'}
                            actionBtnTxt= {'go Home'}
                            showModal= {isDeleted}
                            isBlocking={true}
                            actionType ={actionType}
                            shouldDefaultRedirect= {true}
                            defaulltRedirectURI= {'/Home'}
                            timmer={AppConfig.ActivityForm.FormRedirectionTimmer}
                        />
                ):''}
                {isCreated?(
                    <ActionScreen 
                        subText= {subText+' Cloned. Redirecting to newly created '+subText+'...'}
                        dialogTitle= {'Created Successfully'}
                        isBlocking={true}
                        actionBtnTxt= {'go Home'}
                        showModal= {isCreated}
                        actionType ={actionType}
                        shouldDefaultRedirect= {true}
                        defaulltRedirectURI= {redirectURI}
                        timmer={AppConfig.ActivityForm.FormRedirectionTimmer}
                    />
                ):''}
                {!canEdit && ActUserRole !=="Admin" && !viewForm &&!isClone?(
                    <ActionScreen 
                        subText= {'Do not have permission to edit the '+ subText}
                        dialogTitle= {'Access Denied'}
                        isBlocking={false}
                        actionBtnTxt= {'go Home'}
                        showModal= {true}
                        isActionBtnVisible={true}
                        shouldDefaultRedirect={false}
                        leftBtnText= {'Go Home'}
                        leftBtnURI= {'/Home'}
                        rightBtnText= {'My Activities'}
                        rightBtnURI= {'/MyActivities'}
                        actionType ={"Denied"}
                    />
                ):''}
            </div>
        );       
    } 
    public render() {
        const {office} =this.props;
        const {ShiftTimmings, Locations, Equipments, editOfficeSpace, formFieldKey, viewForm, errorMsgs, isLoaded, locationEquipKey, selectedFor, 
            LSAFullyBooked, ActSyncMsg, ActUserRole, showErrorMsg,errorMsg, canEdit, IsRecordEditable, hideCloneDialog, ShowSyncBtn, ActivityNotSaved, ActSyncData,
            isTBDSelected, hideCancelDialog, hideDeleteDialog, hideUpdateDialog, activityNotes, SaveFlag, isClone, isRedirect} = this.state;
        const formHeading1 = viewForm? 'View ':'Edit ';
        const formHeading2 = 'Office Usage';
        const dialogMsg = AppConfig.ActivityForm.FullyBookedDialogMsg;
        var updateTxt ='Are you sure you want to update the '+formHeading2+'? Please add notes for the update.';
        var updateTxtAdmin ='Are you sure you want to update the '+formHeading2+' which belongs to past? Please add notes for the update.';
        var lsaFullyBooked = dialogMsg;
        updateTxt = LSAFullyBooked?lsaFullyBooked+updateTxt:updateTxt;
        updateTxtAdmin = LSAFullyBooked?lsaFullyBooked+updateTxt:updateTxt;
        const maxDate = this.context.MaxDate?this.context.MaxDate:AppConfig.ActivityForm.MaxDate;
        return (
            <div className="formMain">
            {isLoaded ? (
                <> 
                    <FormWrapper FormHeading={formHeading1+formHeading2}>
                        {this.props.ParentId?(this.renderCloneInfoMsg()):''}
                        {ActSyncMsg?(this.renderActSyncMsg(ActSyncMsg)):''}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row" style={{marginBottom:"5px"}}>
                                <div className="ms-Grid-col ms-sm12">
                                    <div className="ms-Grid-col ms-sm4"> 
                                        <div style={{width: "100%",display:"flex",justifyContent:"flex-start"}}>
                                            <ActivityDateOffset key={formFieldKey.toString()}
                                                activityDate={editOfficeSpace.activityDate}
                                                viewForm={viewForm}
                                                isPast={ActUserRole==="Admin"}
                                                ExpId={editOfficeSpace.ExperimentId}
                                                ExpDate={editOfficeSpace.ExperimentStartDate}                                                
                                                MaxDate={maxDate}
                                                errorMsgs={errorMsgs}
                                                handleFormFieldChange={this.handleActDateChange}/>
                                            <div style={{width: "2%"}}></div>
                                            <div style={{width: "45%"}}>
                                                <Dropdown
                                                    required
                                                    placeholder="Select Project / Study"
                                                    label="Project / Study"
                                                    disabled={viewForm}
                                                    selectedKey={editOfficeSpace.ProjectId}
                                                    options={this.projectsDropDownOptions}
                                                    errorMessage={errorMsgs.ProjectId}
                                                    onChange={this.handleProjectChange}
                                                />
                                            </div>
                                        </div>
                                        <TextField 
                                            required
                                            label="Activity Name"
                                            name="activityName"
                                            placeholder="Activity Name"
                                            disabled={viewForm}
                                            value={editOfficeSpace.activityName}
                                            errorMessage={errorMsgs.activityName}
                                            onChange={this.handleTextFieldsChange}/>
                                        <TextField 
                                            multiline={true} 
                                            rows={3} 
                                            style={{height:"50px"}} 
                                            label="Activity Description" 
                                            name="activityDescription"
                                            placeholder="Activity Description"
                                            value={editOfficeSpace.activityDescription}
                                            errorMessage={errorMsgs.activityDescription}
                                            onChange={this.handleTextFieldsChange}
                                            styles={descFieldClass}/>       
                                        <PersonVisitor key={formFieldKey.toString()}
                                            errorMsgs ={errorMsgs}
                                            viewForm={viewForm}
                                            selectedFor={selectedFor}                                        
                                            showVisitorField ={editOfficeSpace.showVisitorField}
                                            visitorEmail={editOfficeSpace.visitorEmail}
                                            handleOnChange={this.handlePersonVisitorChange}/>  
                                        <div style={{width: "100%",display:"flex",justifyContent:"space-between"}}>
                                            <div style={{width: "31%"}}>
                                                <div style={{display:"flex",alignItems:"center",justifyContent:"left"}}>
                                                    <Label className={'requiredLabel'} title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts.'}>Shift(s)</Label>
                                                    <div style={{fontSize:12}}>
                                                        <Icon style={{fontSize:"14px",cursor:"pointer",verticalAlign:"middle"}} iconName={'Info'}  
                                                            title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts '}/>
                                                    </div>
                                                </div>
                                                <select 
                                                    style={{width: "100%", height: "200px",fontSize:"12px", color:"black!important"}}
                                                    multiple={true}
                                                    id={"selectedShiftIds"}
                                                    title={'Use SHIFT to select a range of shifts,\nUse CTRL to select non-contiguous shifts.'}
                                                    required={true}
                                                    onChange={e => this.handleShiftChange(e)}>
                                                        {this.renderShifts(ShiftTimmings,editOfficeSpace.selectedShiftIds)}
                                                </select>
                                                <Link name={"Shift"} onClick ={this.handleMultiSelectClear} style={{fontSize:"12px", color:"black!important"}}>Clear</Link>
                                                {errorMsgs.selectedShiftIds?this.renderErrorMsgForField(errorMsgs.selectedShiftIds,'selectedShiftIdsError'):''}
                                            </div>
                                        </div>
                                    </div>                    
                                    <div  className="ms-Grid-col ms-sm8 shiftLocation heightFix" >
                                        <LocationShiftAvailability key={formFieldKey.toString()}
                                            selectedLocations={editOfficeSpace.selectedLocations}
                                            sendLsaArr ={this.updateStateLSA}
                                            Shifts={ShiftTimmings}
                                            office={false}
                                            Locations={Locations}
                                            activityDate={formatActivityDate(editOfficeSpace.activityDate)}/>
                                    </div>
                                </div>                                                             
                            </div>  
                            {showErrorMsg && this.renderErrorMsg(errorMsg)}
                            {!canEdit && ActUserRole !=="Admin" && this.renderErrorMsg('Access Denied: This record cannot be modified. Only a system Administrator can delete the activity.')}
                            {!canEdit && ActUserRole ==="Admin" && this.renderErrorMsg('As a System Administrator only you can modify this activity. The activity is either from  past or inactive.')}
                            <div className={"row formHeadflex"} key="divButtons">
                                {viewForm && (
                                    <div className={'button-right'}>
                                        <div className={'leftBtns'}>
                                            {canEdit && showActControl(this.context,ActUserRole,RoleVerbs.Edit) && (
                                                <PrimaryButton
                                                    key="EditView1"
                                                    text="Edit"
                                                    iconProps={{iconName:"Edit"}} 
                                                    data-action={'Edit'}
                                                    onClick={this.handleEditClick}
                                                /> 
                                            )}                                        
                                            {!IsRecordEditable && showActControl(this.context,ActUserRole,RoleVerbs.EditPast) && (
                                                <PrimaryButton
                                                    key="EditView2"
                                                    text="Edit"
                                                    iconProps={{iconName:"Edit"}} 
                                                    data-action={'Edit'}
                                                    onClick={this.handleEditClick}
                                                 /> 
                                            )}                                        
                                            {ActUserRole !== "None" &&(  
                                                <ButtonWithDialog key={'CloneView1'}
                                                    CustClassName ={"BlockingDialog"}
                                                    iconString={'Copy'}
                                                    hideDialog={hideCloneDialog}
                                                    buttonTxt={'Clone'}
                                                    onBtnClick={this.handleCloneClick}
                                                    buttonType={'Primary'}
                                                    dialogTitle={'Clone?'}
                                                    subText={"This will create a copy of this "+formHeading2+" and display it so you can update the date and other details. Do you want to proceed?"}
                                                    isBlocking={false}
                                                    handleAction={this.handleAddClick}
                                                    actionBtnTxt={'Yes'}
                                                    dismisBtnTxt={'No'}>                                            
                                                </ButtonWithDialog>
                                             )}                                                                                     
                                            <OutlookSyncForm ActivityDetails={{...editOfficeSpace}} Shifts={ShiftTimmings} 
                                                Locations={Locations} ActivitySyncData={ActSyncData} isSynched={ActSyncData.length>0} 
                                                disabled={!ShowSyncBtn || ActivityNotSaved ||isTBDSelected||editOfficeSpace.showVisitorField || this.isEventIdPresent()}
                                                handleActSyncSave={this.handleActSyncSave}/>
                                            <DefaultButton
                                                key="Close"
                                                iconProps={{iconName:"Cancel"}} 
                                                onClick={this.handleCancelAction}
                                                text="Close"/>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            {showActControl(this.context,ActUserRole,RoleVerbs.DeletePast)  && !IsRecordEditable && (
                                                <ButtonWithDialog key={'DeleteView2'}
                                                    CustClassName ={"BlockingDialog"}
                                                    iconString={'Delete'}
                                                    hideDialog={hideDeleteDialog}
                                                    buttonTxt={'Delete'}
                                                    onBtnClick={this.handleDelete}
                                                    buttonType={'Primary'}
                                                    dialogTitle={'Delete?'}
                                                    subText={'The activity belongs to the past. Are you still sure you want to delete the activity? '}
                                                    isBlocking={false}
                                                    handleAction={this.handleDeleteAction}
                                                    actionBtnTxt={'Delete'}
                                                    dismisBtnTxt={'Cancel'}>                                             
                                                </ButtonWithDialog>
                                            )}
                                            {showActControl(this.context,ActUserRole,RoleVerbs.DeletePast) && IsRecordEditable && (
                                                <ButtonWithDialog key={'DeleteView1'}
                                                    CustClassName ={"BlockingDialog"}
                                                    iconString={'Delete'}
                                                    hideDialog={hideDeleteDialog}
                                                    buttonTxt={'Delete'}
                                                    onBtnClick={this.handleDelete}
                                                    buttonType={'Primary'}
                                                    dialogTitle={'Delete?'}
                                                    subText={'The activity either belongs to the past or is inactive. Are you still sure you want to delete the activity? '}
                                                    isBlocking={false}
                                                    handleAction={this.handleDeleteAction}
                                                    actionBtnTxt={'Delete'}
                                                    dismisBtnTxt={'Cancel'}>                                             
                                                </ButtonWithDialog>
                                            )}  
                                        </div>
                                    </div>
                                )}                               
                                <div className={viewForm || isClone?"displayNone":"button-right"}>
                                    <div className={'leftBtns'}>
                                        {showActControl(this.context,ActUserRole,RoleVerbs.Edit) && (
                                            <ButtonWithDialog key={'update'}
                                                CustClassName ={"BlockingDialog"}
                                                hideDialog={hideUpdateDialog}
                                                buttonTxt={'Update'}
                                                iconString={"Edit"}
                                                onBtnClick={this.handleFormSaveClick}
                                                buttonType={'Primary'}
                                                dialogTitle={'Update?'}
                                                subText={ActUserRole === "Admin"? updateTxtAdmin:updateTxt}
                                                isBlocking={true}
                                                handleAction={this.handleUpdateAction}
                                                actionBtnTxt={'Update'}
                                                dismisBtnTxt={'Cancel'}
                                                disabled={viewForm}>
                                                <div>
                                                    <TextField
                                                        multiline
                                                        label="Notes (Optional)"
                                                        name="activityNotes"
                                                        placeholder="Change Reason"
                                                        value={activityNotes}
                                                        errorMessage={errorMsgs.activityNotes}
                                                        onChange={this.handleNotesChange}
                                                    />
                                                </div>                                               
                                            </ButtonWithDialog>
                                        )}
                                        {ActUserRole !== "None" && (
                                            <ButtonWithDialog key={'CloneUpdate1'}
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'Copy'}
                                                hideDialog={hideCloneDialog}
                                                buttonTxt={'Clone'}
                                                onBtnClick={this.handleCloneClick}
                                                buttonType={'Primary'}
                                                dialogTitle={'Clone?'}
                                                subText={"This will create a copy of this "+formHeading2+" and display it so you can update the date and other details. Do you want to proceed?"}
                                                isBlocking={false}
                                                handleAction={this.handleAddClick}
                                                actionBtnTxt={'Yes'}
                                                dismisBtnTxt={'No'}>                                            
                                            </ButtonWithDialog>
                                        )}                                       
                                        <OutlookSyncForm ActivityDetails={{...editOfficeSpace}} Shifts={ShiftTimmings} 
                                            Locations={Locations} ActivitySyncData={ActSyncData} isSynched={ActSyncData.length>0} 
                                            disabled={!ShowSyncBtn || ActivityNotSaved ||isTBDSelected||editOfficeSpace.showVisitorField || this.isEventIdPresent()}
                                            handleActSyncSave={this.handleActSyncSave}/>
                                        <ButtonWithDialog key={'Cancel12'}
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'Cancel'}
                                                hideDialog={hideCancelDialog}
                                                buttonTxt={'Cancel'}
                                                onBtnClick={this.handleCancelClick}
                                                buttonType={'Default'}
                                                dialogTitle={'Cancel?'}
                                                subText={'Changes made to '+formHeading2+' will not be saved.'}
                                                isBlocking={false}
                                                handleAction={this.handleCancelAction}
                                                actionBtnTxt={'Yes'}
                                                dismisBtnTxt={'No'}>                                            
                                        </ButtonWithDialog>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        {showActControl(this.context,ActUserRole,RoleVerbs.Delete) && IsRecordEditable && (
                                            <ButtonWithDialog key={'Delete1'}
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'Delete'}
                                                hideDialog={hideDeleteDialog}
                                                buttonTxt={'Delete'}
                                                onBtnClick={this.handleDelete}
                                                buttonType={'Primary'}
                                                dialogTitle={'Delete?'}
                                                subText={'Are you sure you want to delete the activity?'}
                                                isBlocking={false}
                                                handleAction={this.handleDeleteAction}
                                                actionBtnTxt={'Delete'}
                                                disabled={viewForm}
                                                dismisBtnTxt={'Cancel'}>                                             
                                            </ButtonWithDialog>
                                        )}
                                        {showActControl(this.context,ActUserRole,RoleVerbs.DeletePast)  && !IsRecordEditable && (
                                            <ButtonWithDialog key={'Delete2'}
                                                CustClassName ={"BlockingDialog"}
                                                iconString={'Delete'}
                                                hideDialog={hideDeleteDialog}
                                                buttonTxt={'Delete'}
                                                onBtnClick={this.handleDelete}
                                                buttonType={'Primary'}
                                                dialogTitle={'Delete?'}
                                                subText={'The activity belongs to the past. Are you still sure you want to delete the activity? '}
                                                isBlocking={false}
                                                handleAction={this.handleDeleteAction}
                                                actionBtnTxt={'Delete'}
                                                disabled={viewForm}
                                                dismisBtnTxt={'Cancel'}>                                             
                                            </ButtonWithDialog>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </>
                ) : (
                <div className="centeredContainer">
                    <Spinner size={SpinnerSize.large} />
                </div>
                )}
                {SaveFlag && hideDeleteDialog?(
                    this.loadSpinner(!isClone?'Updating '+formHeading2+'...':'Cloning '+formHeading2+'...',SaveFlag)
                ):''}
                {SaveFlag && !hideDeleteDialog?(
                    this.loadSpinner('Deleting '+formHeading2+'...',SaveFlag)
                ):''}
                {isRedirect?( <Redirect to={'/Home'} /> ):''}

                {this.handleActionAndRediret()}
            </div>
        );
    }
    renderShifts = (shifts:IActivityShift[],selectedShiftIds:number[]) => {
        let options:any = shifts.map((shift:IActivityShift, key:any) => {
            if(selectedShiftIds.indexOf(shift.Id)!==-1){
                return (
                    <option
                        id={shift.Id?.toString()}
                        key={key + shift.Name}
                        value={shift.Id}
                        selected={true}>
                            {shift.DisplayName}
                    </option>
                );
            }
            else{
                return (
                    <option
                        id={shift.Id?.toString()}
                        key={key + shift.Name}
                        value={shift.Id}
                        selected={false}>
                            {shift.DisplayName}
                    </option>
                );
            }
        });
        return options;
    }
    getOptionsSelected = (options:any) => {
        var optionValues:number[] = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                optionValues.push(parseInt(options[i].id));
            }
        }
        return optionValues;
    }
    alertLSAFullyBooked = (LsaArr:ILocationShiftAvailability[],selectedUserEmail?:string):boolean =>{
        const {editOfficeSpace} = this.state;
        var fullCapLSA:ILocationShiftAvailability[] =  _.filter(LsaArr,{OnAlert:true}), raiseAlert:boolean = false;
        if(fullCapLSA.length>0){
            let filterSelection = _.filter(fullCapLSA,(lsa)=>{
                let shiftSelected = false;
                let locSelected = false;
                if(editOfficeSpace.selectedShiftIds.indexOf(lsa.ShiftId) !==-1){
                    shiftSelected = true;
                }
                if(editOfficeSpace.selectedLocations.indexOf(lsa.LocationId) !==-1){
                    locSelected = true;
                }                
                return shiftSelected && locSelected;
            });
            console.log(filterSelection);
            if(filterSelection.length>0){
                let bookedByEmail:string  =  "";
                if(!selectedUserEmail){
                    bookedByEmail = editOfficeSpace.showVisitorField?editOfficeSpace.visitorEmail:editOfficeSpace.bookedForEmail.length>0?editOfficeSpace.bookedForEmail[0].Email:"";
                }
                else{
                    bookedByEmail = selectedUserEmail;
                }
                for (let selectionIndex = 0; selectionIndex < filterSelection.length; selectionIndex++) {
                    const lsaObj:ILocationShiftAvailability = filterSelection[selectionIndex];
                    let noOfBookedFor:number = 0;
                    if(bookedByEmail && lsaObj.BookedForEmails.indexOf(bookedByEmail) === -1){
                        noOfBookedFor = lsaObj.BookedForEmails.length+1; 
                    }                 
                    if(noOfBookedFor>lsaObj.LocationCapacity){
                        raiseAlert = true;
                    }
                }
            }
        }
        return raiseAlert;
    }
    validateFields = (editOfficeSpace:IActivity) => {
        let fieldsArr = ['activityName','visitorEmail','ProjectId'],
            fieldValueArr:any = editOfficeSpace,
            flag = true,
            errorArr = ['Activity Name is required field.','Visitor Email is required field.'];
        try {
            for (let i = 0; i < fieldsArr.length; i++) {
                let eachField = fieldsArr[i];
                if(eachField === 'visitorEmail'){
                    if(editOfficeSpace.showVisitorField){
                        const emailId = fieldValueArr[eachField]; 
                        if(emailId === ''){
                            this.addValidationError(errorArr[i], fieldsArr[i], flag);
                            flag = false;
                        }
                        else{
                            let checkFlag = validateEmail(emailId);
                            if (!checkFlag) {
                                let errMsg ='Visitor Email field should contain valid email.';
                                this.addValidationError(errMsg, fieldsArr[i], flag);
                                flag = false;
                            }
                        }
                    }
                }else if(eachField === 'ProjectId'){
                    if(fieldValueArr[eachField] === 0 ){
                        let errMsg ='Project/Study is required field.';
                        this.addValidationError(errMsg, fieldsArr[i], flag);
                        flag = false;
                    }
                }else {
                    if(fieldValueArr[eachField] === '') {
                        this.addValidationError(errorArr[i], fieldsArr[i], flag);
                        flag = false;
                    }
                }
            }
            if (isNaN(new Date(editOfficeSpace.activityDate).getTime())) {
                this.addValidationError('Activity Date is required field.', 'activityDate', flag);
                flag = false;
            }
            else{
                var currDate = moment(formatActivityDate(today)),
                currActivityDate = moment(editOfficeSpace.activityDate);
                var diffDates = currDate.diff(currActivityDate,'days',true);
                if( diffDates>=1 && this.state.ActUserRole !=="Admin"){
                    this.addValidationError('Activity Date should be selected from future dates.', 'activityDate', flag);
                    flag = false;
                }
            }
            if (editOfficeSpace.selectedShiftIds.length<1) {
                this.addValidationError('Activity Shift is required field.', 'selectedShiftIds', flag);
                flag = false;
            }
            if (!editOfficeSpace.showVisitorField && editOfficeSpace.bookedForEmail.length<1) {
                this.addValidationError('Person Name is required field.', 'bookedForEmail', flag);
                flag = false;
            }
        } catch (error) {
            console.log(error);
            flag = false;
        }
        return flag;
    }
    addValidationError = (message:string, id:string, flushErrors:boolean) => {
        this.setState((previousState:any) => {
            let errorMessageArr:any = {};
            if (!flushErrors) {
                errorMessageArr = {...previousState.errorMsgs};
            }
            errorMessageArr[id] = message;
            return {
                errorMsgs: errorMessageArr,
            };
        });
    }
    IsDeleteJobOnActSync = ():boolean =>{
        const {ActSyncData} = this.state;
        let statusArr:string[] =_.map(ActSyncData,'JobStatus');
        if(statusArr.indexOf('ToDelete')!==-1){
            return false;
        }
        return true;
    }
    shouldUpdateActSync = (activityDate:Date,shiftsArr:any[] ):boolean =>{
        let flag = false;     
        let  initialActDate:any = new Date(this.initialEditActivity.activityDate);
        let initialShifts:any =  this.initialEditActivity.selectedShiftIds;
        if(initialActDate.getDate() !== activityDate.getDate() || initialActDate.getMonth() !== activityDate.getMonth() || initialActDate.getFullYear() !== activityDate.getFullYear()){
            return true;
        }
        if(shiftsArr.length !==initialShifts.length){
            return true;
        }
        for (let index = 0; index < shiftsArr.length; index++) {
            const currShift = shiftsArr[index];
            if(initialShifts.indexOf(currShift) ===-1){
                return true;
            }
        }
        for (let index = 0; index < initialShifts.length; index++) {
            const initialShift = initialShifts[index];
            if(shiftsArr.indexOf(initialShift) ===-1){
                return true;
            }
        }
        return false;
    }
    isEventIdPresent = ():boolean =>{
        let disable:boolean = false;
        const {ActSyncData} = this.state;
        let eventIDs:string[]= _.map(ActSyncData,'EventId');
        for (let index = 0; index < eventIDs.length; index++) {
            const evenId = eventIDs[index];
            if(!evenId){
                disable = true;  
            }
        }
        let statusValues:string[]= _.map(ActSyncData,'JobStatus');
        for (let index = 0; index < statusValues.length; index++) {
            const status = statusValues[index];
            if(status === 'ToDelete' || status === 'ToInsert' ||status === 'ToUpdate'){
                disable = true;  
            }
        }
        return disable;
    } 
    handleEditClick = () =>{
        this.setState({
            viewForm:false
        });
    }
    handleActSyncSave = (actSyncArr:IActivitySync[],action:string)  =>{
        if(action ==="Delete"){
            this.setState({
                ActSyncData:[],
                ActSyncMsg:action
            });
        }
        else{
            this.setState({
                ActSyncData:actSyncArr,
                ActSyncMsg:action
            });
        }
    } 
    handleNotesChange = (event:any)=>{
        var val = event.target.value;
        var notes:string ='';
        if(val.trim() === '') {
            notes= '';
        } else {
            notes = val;
        }
        this.setState({
            activityNotes:notes
        });
    }
    handleCancelClick = () =>{
        const {hideCancelDialog} = this.state;
        this.setState({
            hideCancelDialog:!hideCancelDialog
        });
    }
    handleCancelAction = (event:any) => {
        event.preventDefault();
        this.setState({
            isRedirect:true
        });
    }  
    handleFormSaveClick = (ev:any) => {
        ev.preventDefault();
        const {editOfficeSpace,hideUpdateDialog,errorMsgs,LsaArr} = this.state;
        var flag = this.validateFields(editOfficeSpace);
        if (flag) {
            var alertFlag = this.alertLSAFullyBooked(LsaArr);
            errorMsgs.activityNotes = ''
            this.setState({
                hideUpdateDialog:!hideUpdateDialog,
                activityNotes:'',
                LSAFullyBooked:alertFlag,
                showErrorMsg: false,
                errorMsg: '',
                errorMsgs: InitialErrorMsg,
            });            
        } else {
            this.setState({
                showErrorMsg: true,
                errorMsg: AppConfig.ActivityForm.ErrorValidationMessage,
            });
        }
    }   
    handleUpdateAction = () =>{
        const {activityNotes,editOfficeSpace,isClone,errorMsgs} = this.state;
        this.setState({
            hideUpdateDialog:false
        });
        // if(activityNotes.length<1){
        //     errorMsgs.activityNotes = 'Please enter detailed notes before you delete.';
        //     this.setState({
        //         hideUpdateDialog:false,
        //         errorMsgs:errorMsgs
        //     });
        // }
        // else{
            errorMsgs.activityNotes = '';
            this.setState({
                SaveFlag:true,
                errorMsgs
            });
            this.callForUpdate(editOfficeSpace,AppConfig.ActivityForm.OfcAndIndiActivityType,activityNotes);
        // }
    }
    callForUpdate = (editOfficeSpace:any,activityType:string,activityNotes:string) =>{
        this.setState({SaveFlag:true});
        const {editOfficeSpaceId, ActSyncData} = this.state;
        editOfficeSpace.Id= editOfficeSpaceId;
        if(this.props.office){
            editOfficeSpace.selectedEquipments = [];
            editOfficeSpace.selectedLocations = [AppConfig.ActivityForm.officeSpaceUsageLocation];
        }
        let notesWithUserProfile = activityNotes +" - Modified by " + this.context.userName;        
        updateActivity(editOfficeSpace,activityType,notesWithUserProfile)
        .then(response => {
            let activityDate:any = this.state.editOfficeSpace.activityDate;
            let shiftsArr:any =  this.state.editOfficeSpace.selectedShiftIds;
            let shiftTimings:any =  this.state.ShiftTimmings;           
            if(this.shouldUpdateActSync(activityDate, shiftsArr) && ActSyncData.length>0 && this.IsDeleteJobOnActSync()){              
                //update act sync if act date/ shift are changed
                let shiftTimingsArr = SyncService.getShiftTimingsArr(activityDate, shiftsArr, shiftTimings);
                let clubShiftTimmings = SyncService.clubShiftTimings(shiftTimingsArr);
                ActivitySyncService.CallActUpdateFunction(this.state.editOfficeSpaceId, editOfficeSpace.ExperimentId, clubShiftTimmings, this.context.userName);
            }
            this.setState({SaveFlag:false, hideUpdateDialog:true,isUpdated:true,actionType:"Update"});
        }).catch(error => {
            this.setState({
                SaveFlag:false,
                hideUpdateDialog:true,
                actionType:"Failed",
                isUpdated:false,
                showErrorMsg: true,
                errorMsg:AppConfig.ActivityForm.UpdateErrorMessage + error
            });
            console.log(error);
        });
    }
    handleCloneClick = (ev:any) => {
        ev.preventDefault();
        const {hideCloneDialog} = this.state;
        this.setState({
            hideCloneDialog:!hideCloneDialog
        });      
    }
    handleAddClick = (ev:any) => {
        ev.preventDefault();
        const {editOfficeSpace} = this.state;
        var flag = true//this.validateFields(editOfficeSpace);
        if (flag) {
            this.setState({
                showErrorMsg: false,
                errorMsg: '',
                errorMsgs: InitialErrorMsg,
            });
            this.callForCreate(editOfficeSpace,AppConfig.ActivityForm.OfcAndIndiActivityType);
        } else {
            this.setState({
                showErrorMsg: true,
                errorMsg: AppConfig.ActivityForm.ErrorValidationMessage,
            });
        }
    }
    callForCreate = (editOfficeSpace:IActivity,activityType:string) =>{
        this.setState({SaveFlag:true,isClone:true,hideCloneDialog:true});
        // let expId = editOfficeSpace.ExperimentId?editOfficeSpace.ExperimentId:null;
        let emailId = this.context.userName;
        let name = this.context.name;
        let jsonObj:any = {ActivityId:editOfficeSpace.Id,UserEmail:emailId,UserName:name};      
        cloneActivity(jsonObj).then(response => {
        let newId:any  = response?.id;
        this.setState({SaveFlag:false,isCreated:true,viewForm:false,editOfficeSpaceId:newId,actionType:"Clone"});
        }).catch(error => {
            this.setState({
                SaveFlag:false,
                actionType:"Failed",
                showErrorMsg: true,
                errorMsg:AppConfig.ActivityForm.CloneErrorMessage +error
            });
            console.log(error);
        });
    }
    handleDelete = () =>{
        const {hideDeleteDialog,errorMsgs} = this.state;
        errorMsgs.activityNotes = ''
        this.setState({
            hideDeleteDialog:!hideDeleteDialog,
            errorMsgs:errorMsgs,
            activityNotes:''
        });
      
    }
    handleDeleteAction= () =>{
        const {errorMsgs} = this.state;
        let actId:number = this.state.editOfficeSpaceId;
        errorMsgs.activityNotes = '';
        this.setState({
            hideDeleteDialog:false,
            SaveFlag:true,
            errorMsgs
        });
        let notesWithUserProfile = "Deleted by " + this.context.userName; 
        deleteActivityRecordByID(actId,notesWithUserProfile)
        .then(response => {
            ActivitySyncService.CallActDeleteFunction(actId);
            this.setState({SaveFlag:false, hideDeleteDialog:true,isDeleted:true,actionType:"Delete"});
        }).catch(error => {
            this.setState({
                SaveFlag:false,  
                isDeleted:false,
                actionType:"Failed",
                hideDeleteDialog:true,
                showErrorMsg: true,
                errorMsg:AppConfig.ActivityForm.DeleteErrorMessage + error
            });
            console.log(error);
        });
    }
}
