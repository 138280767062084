import * as React from 'react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import {IconButton} from 'office-ui-fabric-react';
import { formatActivityDate, formatActivityFilterDate } from '../Activity/ActivityService';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import {Label} from 'office-ui-fabric-react/lib/Label';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {Toggle} from 'office-ui-fabric-react/lib/Toggle';
import {
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import PersonField from '../Common/PersonField';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { IProject } from '../Experiment/interfaces/IExperiment';
import _ from 'lodash';
import { IUserPersonaField } from '../Activity/interfaces/IActivityForm';
import { UserInfoContext } from '../../Main';
import './ActivityGrid.css';
import FilterChips from './FilterChips';

const uuidv4 = require('uuid/v4');
const moment = require('moment');

export interface IFilterData{
    FromDate:any;
    ToDate:any;
    ProjectId:string[];
    User:IUserPersonaField[];
    hidePastAct:boolean;
    isFilterApplied:boolean;
}
export interface IActivityGridFiltersProps {
    gridLabel:string;
    Projects:IProject[];
    filterValues:IFilterData;
    handleApplyFilter: (filters:IFilterData) =>void;
    handleDismissOnClear: () =>void;
}

export interface IActivityGridFiltersState {
    showFilter:boolean;
    minDate:any;
    Projects:IProject[];
    FilterValues:IFilterData;
    filterKey:any;
    isFilterChanged:boolean;
    errorMessage:string;
    
}


const controlClass = mergeStyleSets({
    control: {
      margin: '0 0 15px 0',
      maxWidth: '300px',
    },
});
const DayPickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
  
    isRequiredErrorMessage: 'Start date is required.',
  
    invalidInputErrorMessage: 'Invalid date format.',
};
const firstDayOfWeek = DayOfWeek.Sunday;
export default class ActivityGridFilters extends React.Component<IActivityGridFiltersProps, IActivityGridFiltersState> {
    constructor(props: IActivityGridFiltersProps) {
        super(props);
        this.state = {
            showFilter:false,
            Projects:[],
            FilterValues: {  
                hidePastAct:true,      
                FromDate: new Date(formatActivityDate()), 
                ToDate:new Date(formatActivityDate()),
                ProjectId:[],
                User: [],
                isFilterApplied:false
            },
            filterKey:uuidv4(),
            isFilterChanged:false,
            minDate:new Date(formatActivityDate()),
            errorMessage:''
        };
        ActivityGridFilters.contextType = UserInfoContext;
    }
    initialFilterValues:IFilterData = { 
        hidePastAct:true,      
        FromDate: new Date(formatActivityDate()), 
        ToDate:new Date(formatActivityDate()),
        ProjectId:[],
        User: [],
        isFilterApplied:false
    };
    componentDidMount = () => {
        // this.setInitialFilter();
        const { filterValues,Projects} = this.props;
        this.initialFilterValues = filterValues;
        this.setState({
            FilterValues: {...filterValues},
            Projects:Projects,
            minDate:filterValues.hidePastAct?new Date(formatActivityDate()):undefined
        });
    }
    componentDidUpdate =(prevProps:IActivityGridFiltersProps,prevState:IActivityGridFiltersState) =>{
        const {Projects,filterValues} = this.props;
        let isRealUpdate:boolean = false;
        if(prevProps.Projects.length !== Projects.length){
            this.setState({
                Projects
            });
        }
        if(prevProps.filterValues.hidePastAct !== filterValues.hidePastAct){           
            isRealUpdate= true;
        }
        if(prevProps.filterValues.FromDate !== filterValues.FromDate){           
            isRealUpdate= true;
        }
        if(prevProps.filterValues.ToDate !== filterValues.ToDate){           
            isRealUpdate= true;
        }
        if(this.CheckProjectField(prevProps.filterValues.ProjectId,filterValues.ProjectId)||this.CheckProjectField(filterValues.ProjectId, prevProps.filterValues.ProjectId)){
            isRealUpdate= true;
        }
        if(this.CheckPersonsInField(prevProps.filterValues.User,filterValues.User) || this.CheckPersonsInField(filterValues.User, prevProps.filterValues.User)){
            isRealUpdate= true;
        }
        if(isRealUpdate){
            this.initialFilterValues = filterValues;
            this.setState({
                FilterValues:{...filterValues},
                minDate:filterValues.hidePastAct?new Date(formatActivityDate()):undefined
            });
        }
    }
    CheckPersonsInField = (prevPeople :IUserPersonaField[]|null,CurrPeople: IUserPersonaField[]|null) =>{
        let flag:boolean = false;
        var currPeopleEmail:string[] = CurrPeople?_.map(CurrPeople,'Email'):[];
        if(prevPeople){
            for (let people = 0; people < prevPeople.length; people++) {
                const prevPerson:IUserPersonaField = prevPeople[people];
                if(currPeopleEmail.indexOf(prevPerson.Email) === -1){
                    flag = true;
                }
            }
        }
        return flag;
    }
    CheckProjectField = (prevProject :string[]|null,CurrProject: string[]|null) =>{
        let flag:boolean = false;
        if(prevProject){
            for (let Project = 0; Project < prevProject.length; Project++) {
                const prevPrjctId:string = prevProject[Project];
                if(CurrProject?.indexOf(prevPrjctId) === -1){
                    flag = true;

                }
            }
        }
        return flag;
    }
    setInitialFilter = () =>{
        const {FilterValues} = this.state;
        const {Projects} = this.props;
        let currentUserName:string = this.context.name;
        let currentUserEmail:string = this.context.userName;
        var obj:IUserPersonaField = {} as IUserPersonaField;
        FilterValues.User?.push(obj);
        FilterValues.User[0].DisplayName = currentUserName;
        FilterValues.User[0].UserLogin = currentUserEmail;
        FilterValues.User[0].Email = currentUserEmail;
        FilterValues.ToDate =null;
        this.setState({
            FilterValues,
            Projects,
            filterKey:uuidv4()
        });
    }
    handleClearFilter = () =>{
        const {FilterValues} = this.state;
        let currentUserName:string = this.context.name;
        let currentUserEmail:string = this.context.userName;
        var obj:IUserPersonaField = {} as IUserPersonaField;
        FilterValues.User = [];
        FilterValues.User.push(obj);
        FilterValues.User[0].DisplayName = currentUserName;
        FilterValues.User[0].UserLogin = currentUserEmail;
        FilterValues.User[0].Email = currentUserEmail; 
        FilterValues.FromDate = new Date(formatActivityDate());
        FilterValues.ToDate = null;
        FilterValues.hidePastAct = true;  
        FilterValues.ProjectId = [];
        this.setState({
            FilterValues,
            filterKey:uuidv4(),
            isFilterChanged:true
        });

    }
    handleShowFilter = () => {
        this.setState((prevState) => {
            return {
                showFilter:!prevState.showFilter,
            }
        });
        if(this.state.isFilterChanged){
            this.props.handleDismissOnClear()
        }
    }
    handlePastFilter = () => {
        const {FilterValues} = this.state;
        let minDate:any = undefined;
        if(!FilterValues.hidePastAct){
            FilterValues.hidePastAct = true;
            FilterValues.FromDate = new Date(formatActivityDate());
            // FilterValues.ToDate = new Date(formatActivityDate());
            minDate= new Date(formatActivityDate());
        }
        else{
            FilterValues.hidePastAct = false;
            FilterValues.ToDate = null;
        }
        this.setState({
            FilterValues,
            minDate,
            isFilterChanged:true
        });
    }
    handleFromDateChange = (value:any) => {
        const {FilterValues} = this.state;
        if(value!==FilterValues.FromDate ){
            FilterValues.FromDate = value;
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
        }
    }
    handleToDateChange = (value:any) => {
        const {FilterValues} = this.state;
        if(value!==FilterValues.ToDate ){
            FilterValues.ToDate = value;
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
        }
    }
    handleUserChange = (value:any) => {
        const {FilterValues} = this.state;
        FilterValues.User = value;
        this.setState({
            FilterValues,
            isFilterChanged:true
        });   
    }
    handleProjectChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            const {FilterValues} = this.state;
            let selectedProjects:string[] = item.selected ? [...FilterValues.ProjectId, item.key as string] : FilterValues.ProjectId.filter(key => key !== item.key)
            FilterValues.ProjectId = selectedProjects;
            this.setState({
                FilterValues,
                isFilterChanged:true
            });
            
        }
    }
    handleClearDate =(ev:any) =>{
        let elm = ev.currentTarget.dataset.elm;
        const {FilterValues} = this.state;
        if(elm === "ToDate"){
            FilterValues.ToDate = null;
        }
        else if (elm === "Project") {
            FilterValues.ProjectId = [];
        }
        else{
            FilterValues.FromDate = null;
        }
        this.setState({
            FilterValues,
            isFilterChanged:true
        });
    }
    handleApplyFilter = () => {
        const {FilterValues} = this.state;
        var validFilters:boolean = true;
        if(FilterValues.FromDate && FilterValues.ToDate){
            validFilters = this.CheckDateRange(FilterValues.FromDate,FilterValues.ToDate);
        }
        if(validFilters){
            this.setState({
                showFilter:false,
                errorMessage:''
            });
            FilterValues.isFilterApplied = true;
            this.props.handleApplyFilter(FilterValues);            
        }
        else{
            this.setState({
                errorMessage:"Filters are not valid. To Date cannot be before From date."
            })
        }
    }
    handleDeleteChipFilter =(filter:any) =>{
        const {FilterValues} = this.state;        
        if(filter === "All"){
            FilterValues.User = [];
            FilterValues.FromDate = null;
            FilterValues.ToDate = null;
            FilterValues.hidePastAct = false;  
            FilterValues.ProjectId = [];
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
            this.props.handleApplyFilter(FilterValues);
        }
        else if (filter === "Range") {
            FilterValues.FromDate = new Date(formatActivityDate());
            FilterValues.ToDate = null;
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
            this.props.handleApplyFilter(FilterValues);
        }
        else if (filter === "hidePast") {
            FilterValues.hidePastAct = false;
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
            this.props.handleApplyFilter(FilterValues);
        }
        else if (filter === "User") {
            FilterValues.User = [];
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
            this.props.handleApplyFilter(FilterValues);
        }
        else if (filter === "Project") {
            FilterValues.ProjectId = [];
            this.setState({
                FilterValues,
                filterKey:uuidv4(),
                isFilterChanged:true
            });
            this.props.handleApplyFilter(FilterValues);
        }
        else{
           console.log("Unkonw Filter chip deleted");
        }
        this.setState({
            FilterValues,
            isFilterChanged:true
        });
    }
    CheckDateRange = (fromDate:any, toDate:any):boolean =>{
        var flag:boolean = true;
        var currFromDate = moment(formatActivityDate(fromDate));
        var currToDate = moment(formatActivityDate(toDate));
        var diffDates = currFromDate.diff(currToDate,'days');
        if(diffDates>0){
            flag = false;
        }
        return flag;
    }          
    onFormatDate = (date: any): string => {
        return formatActivityFilterDate(date);
    }
    onRenderFooterContent = ():JSX.Element =>{
        const btnIcon = {iconName: 'ClearFilter'};  
        const applyBtnIcon = {iconName: 'NavigateBackMirrored'};  
        return(
            <div style={{fontSize:16,marginBottom:10,display:"flex"}}>
                <div >
                    <PrimaryButton 
                        // iconProps={applyBtnIcon} 
                        text="Apply"
                        onClick ={this.handleApplyFilter} />
                </div>
                <div style={{ paddingLeft:"15px"}}>
                    <DefaultButton  
                        // iconProps={btnIcon} 
                        text="Reset"
                        onClick ={this.handleClearFilter} />
                </div>            
            </div>
        );
    }
    renderMsg(msg:string,MsgType:MessageBarType){
        return(
            <MessageBar messageBarType={MsgType} className={"errorMsgInfo"}>
                {msg}
            </MessageBar>
        );
    }
    public render() {
        const {showFilter,Projects,FilterValues,filterKey,errorMessage,minDate} = this.state;
        const {gridLabel} = this.props;
        // const btnIcon = {iconName: 'ClearFilter'};  
        const btnIcon = {iconName: 'Clear'}; 
        const filterIcon = {iconName: 'FilterSolid'}; 
        const applyBtnIcon = {iconName: 'NavigateBackMirrored'};  
        return (
            <>
            <div style={{float:"left"}}>
                <div style={{fontSize: "12pt", fontWeight: "bold", textAlign:"left"}}>
                    {gridLabel}
                </div>
            </div>
            <div style={{float:"left",margin:"0px 15px"}}>
               <FilterChips
                    FilterData={FilterValues}
                    Projects={Projects}
                    handleDeleteFilter ={this.handleDeleteChipFilter}
               />
            </div>
            <div style={{float:"right"}}>   
                <div style={{float:"right", paddingTop:"5px", paddingLeft:"5px"}}>
                    {/* <Toggle
                        label=""
                        onText="Hide Filter"
                        offText="Show Filter"
                        checked={showFilter}
                        onChange={this.handleShowFilter}
                    /> */}
                        <IconButton
                            iconProps={filterIcon}
                            style={{color:'black'}}
                            data-elm={'Filter'}
                            title="Filters"
                            ariaLabel="Filters"
                            onClick={this.handleShowFilter}
                        />
                </div>
            </div>
            <div className={"clearfix"} style={{marginBottom:10}}></div>
            <Panel 
                isOpen={showFilter}
                onDismiss={this.handleShowFilter}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
                headerText="Filters"
                // hasCloseButton={false} 
                onRenderFooterContent={this.onRenderFooterContent}
                isFooterAtBottom={true}>             
                <div style={{padding:"5px 0px",fontSize: "12pt", alignItems:"flex-end", display:"flex",textAlign:"left",width:"100%"}}>
                    <div style={{width:"40%",display:"flex",alignItems:"flex-end"}} >
                        <DatePicker
                            className={controlClass.control}
                            label="From Date"
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            formatDate={this.onFormatDate}
                            onSelectDate ={this.handleFromDateChange}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            minDate={minDate}
                            value={FilterValues.FromDate}
                        />
                        <IconButton
                            className={"clearDateFilter"}
                            iconProps={btnIcon}
                            data-elm={'FromDate'}
                            title="Clear"
                            ariaLabel="clear"
                            onClick={this.handleClearDate}
                        />
                    </div>
                    <div style={{width:"2%"}}></div>
                    <div style={{width:"40%",display:"flex",alignItems:"flex-end"}} >
                        <DatePicker
                            className={controlClass.control}
                            label="To Date"
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            formatDate={this.onFormatDate}
                            onSelectDate ={this.handleToDateChange}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            minDate={minDate}
                            value={FilterValues.ToDate}                            
                        />
                        <IconButton
                            className={"clearDateFilter"}
                            iconProps={btnIcon}
                            data-elm={'ToDate'}
                            title="Clear"
                            ariaLabel="clear"
                            onClick={this.handleClearDate}
                        />
                    </div>  
                </div>
                <div style={{padding:"5px 0px",fontSize: "12pt", alignItems:"flex-end", display:"flex",textAlign:"left",width:"35%"}}>
                    <div style={{width:"100%"}}>
                        <Toggle
                            label="Hide Past Activites"
                            onText="Yes"
                            offText="No"
                            checked={FilterValues.hidePastAct}
                            onChange={this.handlePastFilter}/>
                    </div>
                </div>
                <div style={{padding:"5px 0px",fontSize: "12pt", alignItems:"flex-end", display:"flex", textAlign:"left",width:"100%"}}>
                   <div style={{width:"94%"}}>
                        <Dropdown 
                            required={false} 
                            placeholder ={"All Project / Study"}
                            multiSelect
                            label="Project / Study" 
                            selectedKeys = {FilterValues.ProjectId}                      
                            options={Projects?.map((p: { Id: any; Name: any; }, index: any)=>{return {key: p.Id, text: p.Name}})}
                            onChange={this.handleProjectChange}/>
                   </div>
                   <div style={{width:"6%"}}>
                        <IconButton                            
                            className={"clearProject"}
                            iconProps={btnIcon}
                            data-elm={'Project'}
                            title="Clear"
                            ariaLabel="clear"
                            onClick={this.handleClearDate}
                        />
                    </div>
                </div>
                <div style={{padding:"5px 0px",fontSize: "12pt",textAlign:"left",width:"100%"}}>
                    <Label className={'requiredLabel'}>
                        Experiment Owner/ Booked For/ Booked By
                    </Label>
                    <PersonField
                        required={false}
                        description={'Experiment Owner/ Booked For/ Booked By'}
                        placeholderTxt={'Search User...'}
                        property={'userName'}
                        updatePeoplePickerChange={this.handleUserChange}
                        people={FilterValues.User}
                        // getGroups={true}
                        multiple={true}/>
                </div>
                {errorMessage && this.renderMsg(errorMessage,MessageBarType.error)}
            </Panel>
            </>
        );
    }
}
