import * as React from 'react';
import {
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react/lib/MessageBar';
import {Spinner, SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import {getShiftLocationAvaliability, formLocationShiftAvailabilityArr, formatActivityDate} from './ActivityService';
import {Icon} from 'office-ui-fabric-react';
import { ILocationShiftAvailabilityProps, ILocationShiftAvailabilityState, ILocationShiftAvailability } from './interfaces/ILocationShiftAvailability';
import AppConfig from '../../Constants';
import './StickyTable.css';
import _ from 'lodash';
import { IActivityShift } from './interfaces/IActivityForm';


const uuidv4 = require('uuid/v4');

export default class LocationShiftAvailability extends React.Component<ILocationShiftAvailabilityProps,ILocationShiftAvailabilityState> {
    constructor(props:ILocationShiftAvailabilityProps) {
        super(props);
        this.state = {
            isLoaded: false,
            selectedDate: new Date(formatActivityDate()),
            shiftLocationDataArr: [],
            errorMsg:'',
            showErrorMsg:false,
            selectedLocations:[]
        };
    }
    componentDidMount() {
        const {activityDate} = this.props;
        this.setState({
            selectedDate: activityDate,
        });
        this.callForShiftLocationAvailabilityData(activityDate);
    }
    callForShiftLocationAvailabilityData(activityDate:any) {
        const{Locations,Shifts} = this.props;
        if(Locations.length >0 && Shifts.length>0){
            getShiftLocationAvaliability({activityDate})
            .then(shiftLocationAvailabilities => {
                if (shiftLocationAvailabilities) {
                    var lsaArr:any = formLocationShiftAvailabilityArr(Locations,Shifts,shiftLocationAvailabilities,this.props.office);               
                    this.setState({
                        shiftLocationDataArr: lsaArr,
                        isLoaded: true,
                    });
                    this.props.sendLsaArr(lsaArr);
                }
                else{
                    this.setState({
                        isLoaded:true,
                        showErrorMsg: true,
                        errorMsg: AppConfig.ActivityForm.ErrorLSADataFetchMessage
                    }); 
                    console.log("No Shift Location Availabilities");
                }
            })
            .catch(error => {
                this.setState({
                    isLoaded:true,
                    showErrorMsg: true,
                    errorMsg: AppConfig.ActivityForm.ErrorLSADataFetchMessage
                });
                console.log(error);
            });
        }
    }
    componentDidUpdate(prevProps:ILocationShiftAvailabilityProps,prevState:ILocationShiftAvailabilityState ) {
        const {activityDate,Shifts,Locations,selectedLocations} = this.props;
        if (prevProps.activityDate !== activityDate) {
            this.setState({
                isLoaded: false,
            });
            this.callForShiftLocationAvailabilityData(activityDate);
        }  
        if (prevProps.Shifts.length !== Shifts.length || prevProps.Locations.length !== Locations.length) {
            this.setState({
                isLoaded: false,
            });
            this.callForShiftLocationAvailabilityData(activityDate);
        }
        if(selectedLocations.length !== prevProps.selectedLocations.length){
            this.setState({
                selectedLocations
            });
        }
        if (selectedLocations.length>0 && prevProps.selectedLocations.length>0 ) {
            let checkFlag = this.checkIfLocationsSame(prevProps.selectedLocations, selectedLocations);
            if (checkFlag) {
                this.setState({
                    selectedLocations
                });
            }
        }
    }
    checkIfLocationsSame = (prevLocs:number[], currLocs:number[]):boolean => {
        for (let index = 0; index < prevLocs.length; index++) {
            const prevLoc = prevLocs[index];
            if(currLocs.indexOf(prevLoc) ===-1){
                return true;
            }
        }
        for (let index = 0; index < currLocs.length; index++) {
            const currLoc = currLocs[index];
            if(prevLocs.indexOf(currLoc) === -1){
                return true;
            }
        }
        return false;
    }
    renderErrorMsg(msg:string){
        return(
            <MessageBar messageBarType={MessageBarType.error}>
                {msg}
            </MessageBar>
        );
    }
    reduceLSAByLocations = (lsaArr: ILocationShiftAvailability[], shiftArr: IActivityShift[])=>{
        const locationSortedData = _.orderBy([...lsaArr], ['LocationOrder', 'ShiftOrder']);
        let reducedArr: any[] = [];
        _.forEach(locationSortedData, (item, ind)=>{
            let reducedItems = reducedArr.filter((l)=>{return l.LocationId===item.LocationId});
            if(reducedItems.length===0){
                //create a copy of the item with location properties only
                let _locationItem = Object.assign({}, {
                    "LocationId": item.LocationId, 
                    "LocationName": item.LocationName, 
                    "LocationDescription": item.LocationDescription,
                    "LocationOrder":item.LocationOrder
                });
                _.set(_locationItem, "status-"+item.ShiftId, item.Status);
                _.set(_locationItem, "count-"+item.ShiftId, item.NoOfActivities);
                _.set(_locationItem, "color-"+item.ShiftId, item.IndicationColor);
                reducedArr.push(_locationItem);
            }
            else{
                let _locationItem = reducedItems[0];
                _.set(_locationItem, "status-"+item.ShiftId, item.Status);
                _.set(_locationItem, "count-"+item.ShiftId, item.NoOfActivities);
                _.set(_locationItem, "color-"+item.ShiftId, item.IndicationColor);
            }
        });    
        return reducedArr;
    }
    parseAndAdjust = (sname:string) =>{
        try {
            let intValue = sname? parseInt(sname):0;
            if(intValue){
                let shiftHead = intValue<10? "0" + sname:sname;
                return shiftHead;
            }
        } catch (error) {
            return sname;
        }
    }
    render() {
        const {isLoaded, shiftLocationDataArr,showErrorMsg,errorMsg,selectedLocations} = this.state;
        const reducedArr = this.reduceLSAByLocations(shiftLocationDataArr, this.props.Shifts);
        const sortedShifts = _.orderBy([...this.props.Shifts], ['ShiftOrder']);
        let ampmEnd = sortedShifts.length>0 && this.getAmPmEndIndex(sortedShifts);
        let amMidId = sortedShifts.length>0 && this.getAmPmMidIndex(sortedShifts,"AM"); 
        let pmMidId = sortedShifts.length>0 && this.getAmPmMidIndex(sortedShifts,"PM");
        return (
            <div className={'LocationShiftAvailability'}>
                {isLoaded ? (showErrorMsg?this.renderErrorMsg(errorMsg)
                    :(<div>
                        {shiftLocationDataArr.length === 0 ? (
                            <>
                                <MessageBar>
                                    No Activities found for the day.
                                </MessageBar>
                            </>
                        ) : (
                            <table className="stickyTable">
                                <thead>
                                    <tr key={'row1'} className={'trfirst'}>                                        
                                        <th key={'loc1'} style={{padding:0}}><p style={{margin:"-15px"}}></p></th>
                                        {
                                            sortedShifts.map((s,i)=>{ 
                                                if(ampmEnd === s.Id){
                                                    return <th title={s.DisplayName} key={'shift2'+s.Id} style={{padding:0,borderRight:"2px solid white"}}></th>; 
                                                }
                                                else if(amMidId === s.Id || pmMidId === s.Id){
                                                    return <th title={s.DisplayName} key={'shift2'+s.Id} style={{padding:0}}>{s.ExtraName}</th>; 
                                                }
                                                else{
                                                    return <th title={s.DisplayName} key={'shift2'+s.Id} style={{padding:0}}></th>; 
                                                }
                                            })
                                        }
                                    </tr>
                                    <tr key={'row0'} className={'trSecond'}>
                                        <th key={'loc0'} style={{marginBottom:5,minWidth:"60px"}}><p style={{margin:"-15px"}}>Location</p></th>
                                        {
                                            sortedShifts.map((s)=>{ return <th title={s.DisplayName} key={'shift'+s.Id} style={{minWidth: "35px",maxWidth: "35px"}}>{s.ShortName?s.ShortName:s.DisplayName}</th>; })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {reducedArr.map(
                                        (_locItem, _ind) => {
                                            if(selectedLocations.length>0){
                                                if(selectedLocations.indexOf(_locItem.LocationId)!==-1){
                                                    return (
                                                        <tr key={uuidv4()}>                                                    
                                                            <td key={ 'loc' + _locItem.LocationId } title={ _locItem.LocationDescription } style={{minWidth:"60px"}}>
                                                                {_locItem.LocationName}
                                                            </td>
                                                            {
                                                                sortedShifts.map((s)=>{ return (
                                                                    <td key={ 'shift' + s.Id + _ind } title={s.DisplayName +" "+ _locItem.LocationName} className={ 'rowColor' + _.get(_locItem, 'color-'+s.Id,0) }>
                                                                        {this.props.office?_.get(_locItem, 'count-'+s.Id,0):_.get(_locItem, 'status-'+s.Id,0)}  
                                                                    </td>
                                                                );})
                                                            }                                                    
                                                        </tr>
                                                    );
                                                }
                                            }else{
                                                return (
                                                    <tr key={uuidv4()}>                                                    
                                                        <td key={ 'loc' + _locItem.LocationId } title={ _locItem.LocationDescription }>
                                                            {_locItem.LocationName}
                                                        </td>
                                                        {
                                                            sortedShifts.map((s)=>{ return (
                                                                <td key={ 'shift' + s.Id + _ind } title={s.DisplayName +" "+ _locItem.LocationName} className={ 'rowColor' + _.get(_locItem, 'color-'+s.Id,0) }>
                                                                    {this.props.office?_.get(_locItem, 'count-'+s.Id,0):_.get(_locItem, 'status-'+s.Id,0)}  
                                                                </td>
                                                            );})
                                                        }                                                    
                                                    </tr>
                                                );
                                            }
                                        },
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>)
                ) : (
                    <>
                        <div className="sectionSpinner">
                            <Spinner
                                size={SpinnerSize.large}
                                label={'Loading...'}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
    getAmPmMidIndex(sortedShifts: IActivityShift[], type: string) {
        let  groupedArr = _.groupBy(sortedShifts,'ExtraName');
        let amArr:IActivityShift[] = groupedArr[type]; 
        if(amArr?.length>0){
            let index:number = Math.ceil(amArr.length/2);
            let id = amArr[index-1].Id;
            return id;
        }
    }
    getAmPmEndIndex(sortedShifts: IActivityShift[]) {
       let  groupedArr = _.groupBy(sortedShifts,'ExtraName');
       let amArr:IActivityShift[] = groupedArr?groupedArr["AM"]:[]; 
       if(amArr?.length>0){
           let lastObj:IActivityShift = amArr.pop() as IActivityShift;
           let Id = lastObj.Id
           return Id;
       }
    }
}
