import * as React from 'react';
import { UserInfoContext } from '../../Main';
import { IExperiment, IExperimentProps, IExperimentState, IProject, IExperimentErrMsgs, IValidationObj } from './interfaces/IExperiment';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import {Icon} from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown, IDropdownOption, IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DetailsList, IColumn,Selection, IDetailsListStyles,SelectionMode, ConstrainMode, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { Spinner, SpinnerType, SpinnerLabelPosition } from 'office-ui-fabric-react/lib/Spinner';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Label } from 'office-ui-fabric-react/lib/Label';
import FLatDatePickr from '../utils/FLatDatePickr';
import moment from 'moment';
import ExperimentOwnerPicker from '../Common/BookedForEmail';
import { IUserPersonaField, IActivity, IActivityShift } from '../Activity/interfaces/IActivityForm';
import * as Utils from "../utils/Utils";
import CreateActivityForm from "../Activity/CreateActivityForm";
import './Experiment.css';
import ExperimentService from "../../services/experiment-service";
import { Redirect } from 'react-router-dom';
import { IActivityGridModel } from '../ActivityGrid/interfaces/IActivityGridModel';
import { validateExpFields } from './ExperimentService';
import ButtonWithDialog from '../Common/dialogs/ButtonWithDialog';
import AppConfig from '../../Constants';
import { BlockingSpinner } from '../Common/dialogs/BlockingSpinner';
import * as _ from 'lodash';
import EditActivityForm from '../Activity/EditActivityForm';
import ActivityGridService from '../../services/activity-grid-service';
import { DisableExpControl, RolePermission, DisableExpActDeleteControl , DisableExpActCloneControl, checkUserCanChangePast} from '../../services/PermissionsService';
import { formatActivityDate, getShiftData } from '../Activity/ActivityService';
import { ExperimentActOutlookSync } from '../Activity/OutLookEventSync/ExperimentActOutlookSync';
import { IconButton, SpinnerSize, Toggle } from '@fluentui/react';
import { SyncService } from '../Activity/OutLookEventSync/SyncService';
import { IActivitySync } from '../Activity/interfaces/IOutlookSync';
import OutlookButtonForGrid from '../Activity/OutLookEventSync/OutlookButtonForGrid';
import ActivitySyncService from '../../services/activity-sync-service';
import { eachRight } from 'lodash';
const uuidv4 = require('uuid/v4');
enum MessageText{
  "AddExperiment"="You will be able to add activities after you save the experiment.",
  "NoActivities"="Click on Add Activity button to add the first activity to this experiment.",
  "NoEditPastExp"= "The system doesn't allow updates to Experiment Details which have start dates in the past!",
  "NoAdminExpOwner"="Permission Denied: Only a system administrator or the experiment owner can edit an experiment.",
  "UnsavedChangesLost"= "Your unsaved changes in the experiment details section will be lost. Do you still want to close?",
  "ExptDetailsSaved"= "Experiment details saved successfully.",
  "DeleteActToolTip"="Enabled if none of the selected activities occur in the past.",
  "DeleteExptConfirm"="Are you sure you want to delete this experiment and all associated activities?",
  "DeleteActConfirm"="Are you sure you want to delete the selected activity/activities?",
  "PushAllConfirm"="All activities for this experiment, (except for those with TBD shifts), will be pushed to your Outlook calendar. This process is not instant, it can take up to 15 minutes before the items will appear in Outlook. Please confirm this action.",
  "RemoveAllConfirm"="All activities for this experiment will be removed from your Outlook calendar. This process is not instant, it can take up to 15 minutes before the items will appear in Outlook. Please confirm this action.",
  "DeleteNotesError"="Please mention the reason for deleting this experiment.",
  "DeletedExperiment"="Deleted experiments can't be edited.",
  "AdminRights"="As a system administrator, you can edit or delete the experiment even if its cancelled or belongs to past.",
  "NoExperimentWithId"="Couldn't find an experiment with this id",
  "LoadingActivitForm"="Loading Activity Form...",
  "CloneActivitForm"="Cloning Activity...",
  "CreatedActivity"="Please fill all the required fields before saving the experiment.",
  "ReloadActivity"="Reloading Activities..",
  "DeleteActivity"="Deleting...",
  "UnsavedEditForm"="Unsaved changes in the Edit form will be lost. Do you confirm?",
  "UnsavedAddForm"="Unsaved changes in the Add form will be lost. Do you confirm?",
  "UnsavedAddEditForm" = "Unsaved changes in the Add/Edit form will be lost. Do you confirm?",
  "UnsavedAddEditFormViewClick" = "Unsaved changes in the Add/Edit form will be lost. Do you confirm?",
  "CloneExpConfirmation" = "This will create a copy of this experiment and take you there so you can update the date and other details. Do you want to proceed?",
  "CloneActConfirmation" = "This will create a copy of this activity and display it below so you can update the date and other details. Do you want to proceed?",
  "CloneSuccessMessage" = "Experiment Cloned successfully. You can update the dates and other details of the cloned experiment here.",  
  "CloningExpLoaderTxt" = "Cloning...",  
}
const detailsListStyles:Partial<IDetailsListStyles> = {
  headerWrapper:{
      selectors: {
          '& .ms-DetailsHeader-cell:hover': {
              backgroundColor: 'white!important',
          },
      }
  },
};
const dropdownStyles: Partial<IDropdownStyles> = { 
  dropdown :{
      selectors:{
          'disabled':{
              border:'1px solid black',
          }
      }
  },
  dropdownOptionText:{ color: 'black!important'},
  title :{ color: 'black!important'},
  label:{
      color:'black!important'
  }
};

const now = moment().format(AppConfig.PlanExperiment.ExpDateFormat);
export default class Experiment extends React.Component<IExperimentProps, IExperimentState> {
  private _selection: Selection;
  private _pushToOutlook:boolean = false;
  private _removeFromOutlook:boolean = false;
  constructor(props: IExperimentProps) {
    super(props);
    Experiment.contextType = UserInfoContext;
    
    this._selection = new Selection({
      onSelectionChanged: () => this._onItemSelectionChange()
    });  
    

    this.state = {      
      Experiment : {} as IExperiment,
      ExperimentErrMsgs:{} as IExperimentErrMsgs,
      ShiftTimmingsArr:[],
      ActivitySyncIdArr:[],
      ActivitySyncData:[],
      TopLabel: "Add Experiment",
      Projects: [],
      sLAData:[],
      ExperimentActivities: [],
      ShowAddActivity: "None",
      ShowDialog: "None",
      PlanActivityLabel: "Add An Activity",
      InfoMessage: MessageText.AddExperiment,
      Mode: "add",
      EID: 0,
      AID:0,
      SaveExpProcessing: false,
      ActSyncAllSpinner: false,
      RedirectUri: "", 
      PlanexLoading: true,
      HeaderMessage: "",
      HeaderMessageType: MessageBarType.error,
      DisableForm: false,
      DisableAdd: false,
      DisableClone: false,
      DisableDelete: false,
      SelectedActivities: [],
      PreviousStartDate: new Date(now),
      PreviousProjectId:0,
      ExperimentNotSaved: false,
      HideDeleteDialog: true,
      DeleteNotes: "",
      DeleteErrorMessage: "",
      FormActionLoading:false,
      FormAction:"LoadForm",
      FormActionMsg:'',
      FormId:uuidv4(),
      HideCloneDialog: true,
      UserRole:"None"
    }
  }
  
  public render() {
    const { ShowDialog, HeaderMessage, HeaderMessageType, HideDeleteDialog, DeleteNotes, DeleteErrorMessage,
      Experiment, EID,TopLabel, Projects, ExperimentActivities, ShowAddActivity, InfoMessage, Mode, SaveExpProcessing,
      FormActionLoading, FormActionMsg,ExperimentNotSaved,RedirectUri, PlanexLoading, ExperimentErrMsgs,DisableForm, 
      SelectedActivities,AID, FormId,HideCloneDialog,DisableAdd, DisableClone,DisableDelete,UserRole,ActSyncAllSpinner} = this.state;
      var expDate = Experiment.StartDate?Experiment.StartDate:null;
      var isOwner:boolean = UserRole === "ExpOwner";
      const {FormMode} = this.props;
      const maxDate = this.context.MaxDate?this.context.MaxDate:AppConfig.ActivityForm.MaxDate;
    return (
      <div style={{width:"100%", textAlign:"center",overflow:"auto"}}>                          
        { PlanexLoading ? <div style={{paddingTop: "50px"}}><Spinner type={SpinnerType.large} label="Loading..."></Spinner></div> : 
          <div className="ms-Grid">
            <div className="ms-Grid-row" style={{minHeight:"30px"}}></div>
            {
              HeaderMessage ? 
                <div className="ms-Grid-row" style={{
                  width: "90%",
                  overflow: "auto",
                  margin: "0 auto",
                  padding:"0px 0px 10px 0px"}}>
                  <MessageBar key={"HeaderMessageType"}  onDismiss={this.onDismissHeaderMessage} messageBarType={HeaderMessageType} dismissButtonAriaLabel="Close">{HeaderMessage}</MessageBar>
                </div> : ""
            }
            <div className="ms-Grid-row" style={{
              boxShadow: "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px", 
              width: "90%",
              overflow: "auto",
              margin: "0 auto",
              padding:"10px 0px 10px 10px"}}>
              <div className="ms-Grid" dir="ltr" style={{padding:"0px", width: "99%", textAlign:"left"}}>
                <div className="ms-Grid-row"style={{width:"100%",overflow:"auto",margin:"0 auto",paddingBottom:"10px"}}>
                  <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" style={{padding:"0px"}}>
                    <div style={{fontSize: "12pt", fontWeight: "bold", textAlign:"left"}}>
                      {TopLabel}
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"  style={{padding:"0px"}}>
                    { Utils.strEqualsCI(Mode, "EDIT") ?
                      <div style={{float:"right", paddingLeft:"15px"}}>
                          <ButtonWithDialog key={'Delete'}
                            CustClassName ={"BlockingDialog"}
                            iconString={'Delete'}
                            hideDialog={HideDeleteDialog}
                            buttonTxt={'Delete Experiment'}
                            onBtnClick={this.onClickDeleteExp}
                            buttonType={'Primary'}
                            dialogTitle={'Delete'}
                            subText={'Are you sure you want to delete the experiment and associated activities?'}
                            isBlocking={true}
                            handleAction={this.onClickDeleteConfirmation}
                            actionBtnTxt={'Delete'}
                            disabled={DisableForm ||  UserRole === "Actowner" || UserRole === "None" || DisableExpControl(this.context,UserRole,RolePermission.DeleteExperiment)}
                            dismisBtnTxt={'Cancel'}>
                              <div>
                                  <TextField
                                      multiline
                                      label="Notes"
                                      name="deleteNotes"
                                      placeholder="Reason for deleting"
                                      value={DeleteNotes}
                                      errorMessage={DeleteErrorMessage}
                                      onChange={this.onChangeDeleteNotes}
                                  />
                              </div>                                               
                          </ButtonWithDialog>
                      </div> : ""
                    }
                    { Utils.strEqualsCI(Mode, "EDIT") ? 
                      <div style={{float:"right", paddingLeft:"15px"}}>
                        <ButtonWithDialog key={'Clone'}
                          CustClassName ={"BlockingDialog"}
                          iconString={'Copy'}
                          hideDialog={HideCloneDialog}
                          buttonTxt={'Clone Experiment'}
                          onBtnClick={this.onClickCloneExperiment}
                          buttonType={'Primary'}
                          dialogTitle={'Clone Experiment?'}
                          subText={"This will create a copy of this experiment with all its activities. Do you want to proceed?"}
                          isBlocking={false}
                          handleAction={this.onClickConfirmCloneExperiment}
                          actionBtnTxt={'OK'}
                          dismisBtnTxt={'Cancel'}>                                            
                        </ButtonWithDialog>
                      </div> : ""
                    }
                    <div style={{float:"right", paddingLeft:"15px"}}>
                      <PrimaryButton text="Close" iconProps={{iconName:"Cancel"}} onClick={this.onCloseExperiment}></PrimaryButton>
                    </div>
                    <div style={{float:"right", paddingLeft:"15px"}}>
                      <PrimaryButton text="Save" 
                        iconProps={{iconName:"Save"}}
                        disabled={DisableForm || DisableExpControl(this.context,UserRole,RolePermission.EditExperiment)} 
                        onClick={this.onSaveExperiment}>
                      </PrimaryButton>
                    </div>
                    <div style={{float:"right", paddingLeft:"15px"}}>
                      {!SaveExpProcessing || <Spinner type={SpinnerType.large} hidden={false}></Spinner>}
                    </div>
                  </div>
                </div>                 
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                      <TextField disabled={DisableForm} required={true} label="Experiment Name" errorMessage={ExperimentErrMsgs.ExperimentName} value={Experiment.Name} onChange={this.onChangeExperimentName}></TextField>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                      <Dropdown 
                        disabled={DisableForm} 
                        styles={dropdownStyles}
                        required={true} 
                        errorMessage={ExperimentErrMsgs.ProjectId}
                        label="Project / Study" 
                        selectedKey = {Experiment.ProjectId}                      
                        options={Projects?.map((p, index)=>{return {key: p.Id, text: p.Name}})}
                        onChange={this.onProjectChange}></Dropdown>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                      <FLatDatePickr viewForm={DisableForm} required={true} maxDate={maxDate} isPast={UserRole === "Admin" && FormMode.toUpperCase() ==="EDIT"} showNonFormLabel={false} labelHead="Start Date" dateInput={expDate} updateFunc={this.onSelectExpDate}></FLatDatePickr>
                      {ExperimentErrMsgs.StartDate?this.renderErrorMsgForField(ExperimentErrMsgs.StartDate,'StartDate'):''}
                    </div> 
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                      <Label className={'requiredLabel'}>Experiment Owner</Label>
                      <div style={{backgroundColor: "#fff"}}>
                        <ExperimentOwnerPicker  
                          key={FormId}                                            
                          viewForm={DisableForm}
                          required={true}
                          description={'Set an experiment owner'}
                          placeholderTxt={'Start typing the name to search'}
                          property={'ExperimentOwner'}
                          updatePeoplePickerChange={this.onSelectExperimentOwner}
                          people={Experiment.Owner ? Experiment.Owner : []}/>
                      </div>
                      {ExperimentErrMsgs.ExperimentOwner?this.renderErrorMsgForField(ExperimentErrMsgs.ExperimentOwner,'ExperimentOwner'):''}
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <TextField disabled={DisableForm} label="Description" value={Experiment.Description} onChange={this.onChangeExperimentDesc}></TextField>
                    </div>                     
                  </div>              
                </div>           
            </div>
            <div className="ms-Grid-row" style={{minHeight:"20px"}}></div>
            <div className="ms-Grid-row" style={{
              boxShadow: "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px", 
              width: "90%",
              overflow: "auto",
              margin: "0 auto",
              padding:"10px 0px 10px 10px"}}>
              <div className="ms-Grid" dir="ltr" style={{padding:"0px", width: "99%", textAlign:"left"}}>
                <div className="ms-Grid-row"style={{width:"100%",overflow:"auto",margin:"0 auto",paddingBottom:"10px"}}>                  
                  <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" style={{padding:"0px"}}>
                    <div style={{fontSize: "12pt", fontWeight: "bold", textAlign:"left",display:"flex",alignItems:"center"}}>
                      <div>Activities</div>
                      {Utils.strEqualsCI(Mode, "EDIT") && (<IconButton onClick={this.reloadActivityGrid} style={{color:'black'}} iconProps={{iconName:"Refresh"}} title="Refresh Activities" ariaLabel="Refresh Activities" />)}
                    </div>
                  </div>
                  {
                      Utils.strEqualsCI(Mode, "add") ? "" : 
                      <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"  style={{padding:"0px"}}>
                         <div style={{float:"right", display:"Flex",paddingLeft:"15px",borderLeft:"2px solid black"}}>
                          {this.togglePushToRemoveAll()?(
                          <>
                            {ActSyncAllSpinner && ( 
                              <Spinner
                                label=''
                                ariaLive="assertive"
                                labelPosition="left"
                                size={SpinnerSize.small}/>
                              )}
                              <PrimaryButton 
                                  style={{marginLeft:5}}
                                  text="Remove All"
                                  title="Remove All from Outlook"
                                  iconProps = {{ iconName: 'RemoveEvent' }}
                                  disabled={ExperimentActivities.length === 0 || this._removeFromOutlook} 
                                  onClick={this.onClickRemoveAllActivity}>                            
                              </PrimaryButton>
                            </>
                            ):(
                              <>
                                {ActSyncAllSpinner && ( 
                                  <Spinner
                                    label=''
                                    ariaLive="assertive"
                                    labelPosition="left"
                                    size={SpinnerSize.small}/>
                                  )}
                                    <PrimaryButton
                                      style={{marginLeft:5}}
                                      text="Push All"
                                      title="Push All to Outlook"
                                      iconProps = {{ iconName: 'AddEvent' }}
                                      disabled={ExperimentActivities.length === 0 || this._pushToOutlook } 
                                      onClick={this.onClickPushAllActivity}>                            
                                  </PrimaryButton>                                
                              </>
                            )}
                        </div>                     
                        <div style={{float:"right", marginLeft:"15px", marginRight:"15px"}} title={MessageText.DeleteActToolTip}>
                          <PrimaryButton  
                            text="Delete" 
                            title="Delete Activity"
                            iconProps = {{ iconName: 'Delete' }}
                            disabled={ExperimentActivities.length ===0 || (SelectedActivities.length===0) || DisableExpActDeleteControl(this.context,isOwner,DisableDelete)} 
                            onClick={this.onClickDeleteMulActivities}>
                          </PrimaryButton>
                        </div>
                        <div style={{float:"right", paddingLeft:"15px"}}>
                          <PrimaryButton 
                            text="Clone"
                            title="Clone Activity"
                            iconProps = {{ iconName: 'Copy' }}
                            disabled={DisableExpActCloneControl(this.context,UserRole,DisableClone) || (ExperimentActivities.length ===0 || (SelectedActivities.length!==1))} 
                            onClick={this.onClickCloneActivity}>                            
                          </PrimaryButton>
                        </div>
                        <div style={{float:"right", paddingLeft:"15px"}}>
                          <PrimaryButton 
                            text="Edit"
                            title="Edit Activity"
                            iconProps = {{ iconName: 'Edit' }}
                            disabled={(ExperimentActivities.length === 0 || (SelectedActivities.length!==1)) || DisableExpControl(this.context,UserRole,RolePermission.EditExpActivity)} 
                            onClick={this.onClickEditActivity} >                            
                          </PrimaryButton>
                        </div>
                        <div style={{float:"right", paddingLeft:"15px"}}>
                          <PrimaryButton 
                            text="View"
                            title="View Activity"
                            iconProps = {{ iconName: 'View' }}
                            disabled={ExperimentActivities.length ===0 || (SelectedActivities.length!==1)} 
                            onClick={this.onClickViewActivity}>                            
                          </PrimaryButton>
                        </div>
                        <div style={{float:"right", paddingLeft:"15px"}}>
                          <PrimaryButton 
                            text="Add"
                            title="Add Activity"
                            iconProps = {{ iconName: 'CircleAddition' }}
                            disabled={(DisableAdd || UserRole === "Actowner" || UserRole === "None" || ShowAddActivity === "Add" ||DisableExpControl(this.context,UserRole,RolePermission.CreateExpActivity))} 
                            onClick={this.onClickAddActivity}>                            
                          </PrimaryButton>
                        </div>
                      </div>
                  }
                  {ShowDialog === "None"?'':this.renderConfirm()}
                </div>
                <div className="ms-Grid-row">
                  { FormActionMsg?this.renderTimmerMessages(FormActionMsg):""}
                  {FormActionLoading?this.loadSpinner(InfoMessage,FormActionLoading):''}
                </div>
                <div className="ms-Grid-row"style={{width:"100%",overflow:"auto",margin:"0 auto",paddingBottom:"10px"}}>                  
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{padding:"0px"}}>
                  {
                    ExperimentActivities.length > 0 ? 
                      <DetailsList
                        items= {ExperimentActivities} //{this.sortActivities(ExperimentActivities)}
                        columns={this.getActivityColumns()}
                        constrainMode={ ConstrainMode.horizontalConstrained }
                        checkboxVisibility={CheckboxVisibility.always}
                        onShouldVirtualize={ () => false }
                        selection={this._selection} 
                        selectionMode={SelectionMode.multiple}  
                        selectionPreservedOnEmptyClick={false}
                        styles={detailsListStyles}            
                      ></DetailsList> : <span>{InfoMessage}</span>
                  }
                  </div>
                </div>                
              </div>
            </div>
            <div className="ms-Grid-row" style={{minHeight:"20px"}}></div>
            <div className="ms-Grid-row" style={{
              boxShadow: "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px", 
              width: "90%",
              overflow: "auto",
              margin: "0 auto",
              padding:"10px 0px 00px 10px"}}>
              {
                (ShowAddActivity==="Add") ? 
                <div className="ms-Grid" dir="ltr" style={{padding:"0px", width: "99%", textAlign:"left"}}>
                  <div className="ms-Grid-row"style={{width:"100%",overflow:"auto",margin:"0 auto",paddingBottom:"0px"}}>                  
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{padding:"0px"}}>
                      <CreateActivityForm key={"Create"+FormId} handleActivityActions={this.handleActivityActions} NotPopActions ={true} 
                      ExpData={{ExpId:EID,ExpOwnerEmail:Experiment.Owner[0]?.Email,ExpStartDate:Experiment.StartDate,ProjectId:Experiment.ProjectId,
                      ExperimentNotSaved:ExperimentNotSaved}}/>
                    </div>
                  </div>                               
                </div> : ""
              }
              {
                (ShowAddActivity==="Edit" ||ShowAddActivity==="View") ? 
                <div className="ms-Grid" dir="ltr" style={{padding:"0px", width: "99%", textAlign:"left"}}>
                  <div className="ms-Grid-row"style={{width:"100%",overflow:"auto",margin:"0 auto",paddingBottom:"0px"}}>                  
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{padding:"0px"}}>
                      <EditActivityForm key={"Edit"+FormId} Id={AID} viewForm={ShowAddActivity==="View"?true:false} 
                      handleActivityActions={this.handleActivityActions} NotPopActions ={true} 
                      ExpData={{ExpId:EID,ExpOwnerEmail:Experiment.Owner[0]?.Email,ExpStartDate:Experiment.StartDate,
                      ExperimentNotSaved:ExperimentNotSaved,ProjectId:Experiment.ProjectId}}/>
                    </div>
                  </div>                               
                </div> : ""
              }
            </div>
          </div>
        }
        {RedirectUri ? <Redirect to={RedirectUri}></Redirect> : ""}
      </div>
    );
  }
  togglePushToRemoveAll = () =>{
    const {ExperimentActivities,ActivitySyncIdArr} = this.state;
    let expActIds = _.map(ExperimentActivities,'ActivityId');
    let _filterTBD:IActivityGridModel[] = _.filter(ExperimentActivities,(o)=>{return !o.isTbdSelected});
    let uniqIdArr = _.uniq(ActivitySyncIdArr);
    if(ActivitySyncIdArr.length === 0){
      return false;
    } 
    for (var i = 0; expActIds.length < i; i++) {
      if (ActivitySyncIdArr.indexOf(expActIds[i])===-1) return false;
    }  
    for (var i = 0; expActIds.length < i; i++) {
      if (ActivitySyncIdArr.indexOf(expActIds[i])===-1) return false;
    }
    if(uniqIdArr.length !== _filterTBD.length){
      return false;
    }
    return true;
  }
  disablePushAndRemoveAll = () =>{
    const {ActivitySyncData} = this.state;
    let statusArr:string[] =_.map(ActivitySyncData,'JobStatus');
    if(statusArr.indexOf('ToDelete') !== -1 || statusArr.indexOf('ToInsert') !== -1|| statusArr.indexOf('ToUpdate') !== -1  ){
        return true;
    }
    return false
  }
  sortActivities = (activities:any) :any[] =>{
    var sortedItems:any[] = [];
    sortedItems =  _.orderBy(activities,(a,b) =>{
      return new Date(a.ActivityDate);
    });
    return sortedItems;
  }
  renderErrorMsgForField(msg:string,key:any){
    return(
         <div role="alert" key={key}>
            <p className="ms-TextField-errorMessage alertMsg">
                <span data-automation-id="error-message">{msg}</span>
            </p>
        </div>
    );
  }
  loadSpinner = (labelTxt:string,isLoading:boolean) => {
    return (
        <div className="centeredContainer">
            <BlockingSpinner label={labelTxt} hideDialog={!isLoading}/>
        </div>
    );
  }
  handleActivityActions = (property:any,actionType:"UpdatedActivityAndSync"|"UpdateSync"|"RemoveSync"|"PushSync"|"LoadForm"|"CreatedActivity"|"DeleteActivity"|"ClonedActivity"|"UpdatedActivity"|"CloseForm"|"ViewToEdit") => {
    switch (actionType) {
      case "LoadForm":      
        this.setState({
          FormActionLoading:false,
          FormAction:"LoadForm",
          InfoMessage: MessageText.NoActivities
        });
        break;
      case "CloseForm":      
        this.setState({
          ShowAddActivity: "None", 
          FormActionLoading:false,
          FormAction:"LoadForm",
          InfoMessage: MessageText.NoActivities
        });
        break;
      case "CreatedActivity":       
        this.reloadActivityGrid();
        this.setState({
          ShowAddActivity: "None", 
          // FormActionLoading:false,
          FormAction:"ShowMessage",
          FormActionMsg:"Created Activity Successfully!"
        });
        setTimeout(() => {
              this.setState({ FormActionMsg: '' })
        }, 2500);
        break;
      case "UpdatedActivity":       
        this.reloadActivityGrid();
        this.setState({
          ShowAddActivity: "None", 
          // FormActionLoading:false,
          FormAction:"ShowMessage",
          FormActionMsg:"Updated Activity Successfully!"
        });
        setTimeout(() => {
              this.setState({ FormActionMsg: '' })
        }, 2500);
        break;
      case "ClonedActivity":       
        this.reloadActivityGrid();
        this.setState({
          ShowAddActivity: "None", 
          // FormActionLoading:false,
          // AID: parseInt(property), 
          FormAction:"ShowMessage",
          FormActionMsg:"Cloned Activity Successfully. Opened Edit activity form of the cloned activity"
        });
        setTimeout(() => {
              this.setState({ FormActionMsg: '' })
        }, 2500);
        break;
      case "DeleteActivity":       
        this.reloadActivityGrid();
        this.setState({
          ShowAddActivity: "None", 
          // FormActionLoading:false,
          AID: parseInt(property), 
          FormAction:"ShowMessage",
          FormActionMsg:"Deleted Activity Successfully.  "
        });
        setTimeout(() => {
              this.setState({ FormActionMsg: '' })
        }, 2500);
        break;
        case "ViewToEdit":
          this.setState({
            ShowAddActivity: "Edit"
          });
        break;   
        case "UpdateSync":
          if(property.length>0){
            const {ActivitySyncIdArr, ActivitySyncData} = this.state;
            let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== property[0].ActivityId});
            let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== property[0].ActivityId});
            updatedArr = updatedArr.concat(property);
            updatedIdArr.push(property[0].ActivityId); 
            this.setState({
              ActivitySyncData:updatedArr,
              ActivitySyncIdArr:updatedIdArr 
            });
          }
        break;
        case "RemoveSync":
          if(property.length>0){
            const {ActivitySyncIdArr, ActivitySyncData} = this.state;
            let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== property[0].ActivityId});
            let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== property[0].ActivityId});
            this.setState({
              ActivitySyncData:updatedArr,
              ActivitySyncIdArr:updatedIdArr 
            });
          }
        break;
        case "PushSync":
          if(property.length>0){
            const {ActivitySyncIdArr, ActivitySyncData} = this.state;
            let updatedArr:IActivitySync[] = ActivitySyncData.concat(property);
            let updatedSyncIdArr:number[] = ActivitySyncIdArr; 
            ActivitySyncIdArr.push(property[0].ActivityId);
            this.setState({
              ActivitySyncData:updatedArr,
              ActivitySyncIdArr:updatedSyncIdArr
            });
          }
        break;
      default:
        break;
    }
  }
  reloadActivityGrid = () =>{
    const {Experiment,ExperimentActivities} = this.state;
    this.setState({
      FormActionLoading: true,
      InfoMessage: MessageText.ReloadActivity,
    });
    let promises = [];
    promises.push(ExperimentService.getActivitiesForExp(Experiment.Id));
    promises.push(ActivityGridService.getGridShiftLocationAvaliability());
    promises.push(SyncService.getExpActivitySyncForUser(Experiment.Id,this.context?.userName));
    Promise.all(promises).then(responses => {                      
      if (responses) { 
        let _activities: IActivityGridModel[] = [];
        _activities = responses[0] as IActivityGridModel[];
        let sLAData:any = responses[1];
        let actSyncRes:IActivitySync[] = responses[2] as IActivitySync[];
        let syncActIdArr:number[] =  actSyncRes.length>0?_.uniq(_.map(actSyncRes,'ActivityId')):[]; 
        if(syncActIdArr.length !== _activities.length){
          this._pushToOutlook = false;
        }else{
          this._pushToOutlook = true;
        }
          this.setState({
            PlanexLoading: false,
            InfoMessage:'',
            ActivitySyncData:actSyncRes,
            ActivitySyncIdArr:syncActIdArr,
            sLAData:sLAData,
            FormActionLoading:false,
            ExperimentActivities: this.reduecActivityArray([..._activities],sLAData)
          });          
      }
    });
  }
  renderTimmerMessages(msg:string){
    return(
        <MessageBar messageBarType={MessageBarType.success} className={"formupdateInfo"}>
            {msg}
        </MessageBar>
    );
  }
  deleteMulActivities = () => {
    const {SelectedActivities,Experiment} = this.state;
    if (SelectedActivities.length>0) {
      let notes = "Delete activities from experiment " + Experiment.Id+" by "+this.context.userName;
      let idArray = _.map(SelectedActivities,'ActivityId');
      this.setState({
        InfoMessage: MessageText.DeleteActivity,
        FormActionLoading: true,
        ShowDialog:"None",
        ShowAddActivity:"None"
      });
      ExperimentService.deleteActivities({ActivityIds:idArray,Notes:notes}).then((data) => { 
        this.reloadActivityGrid();
        this.setState({
            ShowAddActivity:"None",
            Mode: "EDIT",
            InfoMessage: '',
            FormActionLoading: false,
          }); 
      });
    }
    else{
      this.setState({
        ShowDialog:"None"
      });
    }
  }
  onClickDeleteMulActivities = () =>{
    const {ShowAddActivity} = this.state;
    let flag:boolean = true;
    if(flag){
      flag = false;
      this.setState({ 
        ShowDialog:"DeletePrompt"
      });
    }
  }
  onClickPushAllActivity = () =>{
    this.setState({ 
      ShowDialog:"PushAll"
    });
  }  
  onClickRemoveAllActivity = () =>{
    this.setState({ 
      ShowDialog:"RemoveAll"
    });
  }
  addActClickState = () =>{
    this.setState({
      ShowAddActivity: "Add",
      FormActionLoading:true,
      FormAction:"LoadForm",
      ShowDialog:"None",
      FormId: uuidv4(),
      InfoMessage: MessageText.LoadingActivitForm,
    });
  }
  onClickAddActivity=(ev: any)=>{
    const {ShowAddActivity} = this.state;
    let flag:boolean = true;
    if(ShowAddActivity ==="Edit"){
      flag = false;
      this.setState({ 
        ShowDialog:"UnsavedEditForm"
      });
    }
    if(flag){
     this.addActClickState();
    }
  }
  viewActClickState = () =>{
    const {SelectedActivities} = this.state;
    let acitvityId =SelectedActivities.length>0?SelectedActivities[0].ActivityId:0;
    this.setState({
      ShowAddActivity: "View",
      FormActionLoading:true,
      AID:acitvityId,
      ShowDialog:"None",
      FormAction:"LoadForm",
      FormId: uuidv4(),
      InfoMessage: MessageText.LoadingActivitForm,
    });
  }
  onClickViewActivity=(ev: any)=>{   
    const {ShowAddActivity} = this.state;
    let flag:boolean = true;
    if(ShowAddActivity ==="Edit" || ShowAddActivity ==="Add"){
      flag = false;
      this.setState({ 
        ShowDialog:"UnsavedAddEditFormViewClick"
      });
    }
    if(flag){
      this.viewActClickState();
    }
  }
  editActClickState = () =>{
    const {SelectedActivities} = this.state;
    let acitvityId =SelectedActivities.length>0?SelectedActivities[0].ActivityId:0;
    this.setState({
      ShowAddActivity: "Edit",
      FormActionLoading:true,
      AID:acitvityId,
      ShowDialog:"None",
      FormAction:"LoadForm",
      FormId: uuidv4(),
      InfoMessage: MessageText.LoadingActivitForm,
    });
  }
  onClickEditActivity=(ev: any)=>{    
    const {ShowAddActivity} = this.state;
    let flag:boolean = true;
    if(ShowAddActivity ==="Add"){
      flag = false;
      this.setState({ 
        ShowDialog:"UnsavedAddForm"
      });
    }
    if(flag){
      this.editActClickState();
    }
  }
  cloneActClickState = () =>{
    const {SelectedActivities,Experiment} = this.state;
    let acitvityId =SelectedActivities.length>0?SelectedActivities[0].ActivityId:0;
    this.setState({
      FormActionLoading:true,
      FormAction:"LoadForm",
      ShowAddActivity: "None",
      ShowDialog:"None",
      InfoMessage: MessageText.CloneActivitForm,
    });
    let emailId = this.context.userName;
    let name = this.context.name;
    let jsonObj:any = {ActivityId:acitvityId, UserEmail:emailId, UserName:name};
    ExperimentService.CloneActivity(jsonObj).then((data:any) => {
      if(data && data.id){
        this.reloadActivityGrid();
        this.setState({
          ShowAddActivity: "Edit",
          FormActionLoading:true,
          AID:data.id,
          FormAction:"LoadForm",
          FormId: uuidv4(),
          InfoMessage: MessageText.LoadingActivitForm,
        });
      }
    }).catch((error) =>{
        console.log("Clone act error", error);
    });   
  }
  onClickCloneActivity=(ev: any)=>{
    const {ShowAddActivity} = this.state;
    let flag:boolean = true;
    if(ShowAddActivity ==="Add" || ShowAddActivity ==="Edit"){
      flag = false;
      this.setState({ 
        ShowDialog:"UnsavedAddEditForm"
      });
    }
    if(flag){
      flag = false;
      this.setState({ 
        ShowDialog:"CloneActConfirmation"
      });
    }
    if(flag){
        this.cloneActClickState();
    }
  }
  toggleHideDialog = () =>{
    this.setState({
      ShowDialog:"None"
    });
  }
  pushAllFunction  = () =>{
    let currUser =  this.context?.userName;
    const { ExperimentActivities, ShiftTimmingsArr,ActivitySyncIdArr} = this.state;
    // if(this._pushToOutlook = true;
        this.setState({
          ShowDialog:"None",
          ActSyncAllSpinner:true
        });
        let _filterPastActs:IActivityGridModel[] = _.filter(ExperimentActivities,(o)=>{ 
            let currActivityDate = moment(o.ActivityDate);
            let currDate = moment(new Date());
            let diffDates = currDate.diff(currActivityDate,'days'); 
            if(diffDates>0){
              return false;
            }
            return true;
        });
        let _filterAlreadyPushed:IActivityGridModel[] = _.filter(_filterPastActs,(o)=>{return ActivitySyncIdArr.indexOf(o.ActivityId) === -1 && !o.isTbdSelected});
        if(_filterAlreadyPushed.length>0){
          ExperimentActOutlookSync.pushAllActivities(_filterAlreadyPushed, currUser, ShiftTimmingsArr).then(()=>{
            this.reloadActivityGrid();
            this.setState({
              ActSyncAllSpinner:false,
              ShowAddActivity: "None",
            });
          });
        }
        else{
          this.setState({
            ActSyncAllSpinner:false,
            ShowAddActivity: "None",
            FormAction:"ShowMessage",
            FormActionMsg:_filterPastActs.length>0?"No Activites are present to push to outlook":"All activities belong to past"
          });
          setTimeout(() => {
                this.setState({ FormActionMsg: '' })
          }, 2500);
        }
  }
  removeAllFunction  = () =>{
    let currUser =  this.context?.userName;
    const { Experiment} = this.state;
    // this._removeFromOutlook = true;            
    this.setState({
      ShowDialog:"None",
      ActSyncAllSpinner:true
    });
    ExperimentActOutlookSync.removeAllActSync(Experiment.Id, currUser,true).then(()=>{
      this.reloadActivityGrid();
      this.setState({
        ActSyncAllSpinner:false,
        ShowAddActivity: "None",
      });
    });
  }
  confirmationAction = () =>{
    const {ShowDialog} = this.state;
    switch (ShowDialog) {
      case "UnsavedEditForm":
        this.addActClickState();
        break;
      case "UnsavedAddForm":
        this.editActClickState();
        break;
      case "UnsavedAddEditFormViewClick":
       this.viewActClickState();
        break;
      case "UnsavedAddEditForm":
        this.setState({
          ShowAddActivity:"None",
          ShowDialog:"CloneActConfirmation"
        });
        break;
      case "CloneActConfirmation":
        this.cloneActClickState();
        break;
      case "CloseExpForm":
          this.setState({RedirectUri: "/Home"});
        break;
      case "DeletePrompt":
          this.deleteMulActivities();
        break;
      case "PushAll":
          this.pushAllFunction();
        break;
      case "RemoveAll":
          this.removeAllFunction();
        break;
      default:
        this.toggleHideDialog();
        break;
    }
  }
  renderConfirm =():JSX.Element =>{
    const dialogContentProps = {
      type: DialogType.normal,
      title: 'Missing Subject',
      closeButtonAriaLabel: 'Close',
      subText: 'Do you want to send this message without a subject?',
    };
    const {ShowDialog} = this.state;
    var hideDialog:boolean =true;
    switch (ShowDialog) {
      case "None":
        dialogContentProps.title ='';
        dialogContentProps.subText ='';
        hideDialog = true;
        break;
      case "UnsavedEditForm":
        dialogContentProps.title ='Unsaved Edit Form';
        dialogContentProps.subText = MessageText.UnsavedEditForm;
        hideDialog = false;
        break;
      case "UnsavedAddForm":
        dialogContentProps.title ='Unsaved Add Form';
        dialogContentProps.subText = MessageText.UnsavedAddForm;
        hideDialog = false;
        break;
      case "UnsavedAddEditFormViewClick":
        dialogContentProps.title ='Unsaved Add/Edit Form';
        dialogContentProps.subText = MessageText.UnsavedAddEditFormViewClick;
        hideDialog = false;
        break;
      case "UnsavedAddEditForm":
        dialogContentProps.title ='Unsaved Add/Edit Form';
        dialogContentProps.subText = MessageText.UnsavedAddEditForm;
        hideDialog = false;
        break;
      case "CloneActConfirmation":
          dialogContentProps.title ='Clone?';
          dialogContentProps.subText = MessageText.CloneActConfirmation;
          hideDialog = false;
          break;
      case "CloseExpForm":
          dialogContentProps.title ='Unsaved Form';
          dialogContentProps.subText = MessageText.UnsavedChangesLost;
          hideDialog = false;
          break;
      case "DeletePrompt":
          dialogContentProps.title ='Delete?';
          dialogContentProps.subText = MessageText.DeleteActConfirm;
          hideDialog = false;
          break; 
      case "PushAll":
          dialogContentProps.title ='Push All Activities to Outlook?';
          dialogContentProps.subText = MessageText.PushAllConfirm;
          hideDialog = false;
          break; 
      case "RemoveAll":
          dialogContentProps.title ='Remove All Activities from Outlook?';
          dialogContentProps.subText = MessageText.RemoveAllConfirm;
          hideDialog = false;
          break;
      default:
        break;
    }
    return (
        <>
        <Dialog
          hidden={hideDialog}
          onDismiss={this.toggleHideDialog}
          dialogContentProps={dialogContentProps}
          modalProps={{
              isBlocking: true,
              // styles: {main: {maxWidth: 450}},
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.confirmationAction.bind(this)} text="Yes" />
            <DefaultButton onClick={this.toggleHideDialog.bind(this)} text="No" />
          </DialogFooter>
        </Dialog>
        </>
    );
  }
  componentDidMount(){
    this.loadMasterData().then((res)=>{
      const {FormMode, ExpId} = this.props;    
      switch(FormMode.toUpperCase()){
        case "ADD":
          this.loadAddForm();
          break;
        case "EDIT": 
          this.loadEditForm(ExpId);
          this.setState({
            Mode: "edit",
            EID: ExpId,
            TopLabel: "Edit Experiment"
          });
          break;
      }
    });
  }
  componentDidUpdate(prevProps:IExperimentProps, prevState:IExperimentState) {
    const {Experiment} = this.state;
    if(this.props.ExpId !== prevProps.ExpId && this.props.ParentId!==prevProps.ParentId){     
      this.loadEditForm(this.props.ExpId);
      this.setState({
        Mode: "edit",
        FormActionLoading:true,
        InfoMessage:"Loading...",
        EID: this.props.ExpId,
        TopLabel: "Edit Experiment"
      });
    }
  }
  
  loadMasterData=()=>{
    return new Promise((resolve, reject) => {
      const promises=[];
      promises.push(getShiftData());
      promises.push(ExperimentService.getProjects());
      Promise.all(promises).then((responses) => {
        if(responses && responses.length > 0){
          let shiftsRes:IActivityShift[]   = responses[0] as IActivityShift[];
          let _projects = responses[1].data as IProject[];
          let shifts:IActivityShift[] = _.filter(shiftsRes,{StatusId:AppConfig.ActivityForm.ActiveStatusId}) as IActivityShift[];
            this.setState({
              Projects: _projects,
              ShiftTimmingsArr:shifts
            });     
            resolve(true);
      }
      }).catch(error => {
        console.log(error);
        reject(error);
      });   
    });
  }
  validateForm=()=>{
    const {Mode, Experiment} = this.state;
    if(Utils.strEqualsCI(Mode, "EDIT")){  
      if(this.context.isAdmin){
        this.setState({          
          HeaderMessage: MessageText.AdminRights,
          HeaderMessageType: MessageBarType.error
        });
        return;
      }    
      //validate if experiment is active
      if(Experiment.StatusId!==1){
        this.setState({
          DisableForm:true,
          DisableAdd: true,
          DisableClone: true,
          HeaderMessage: MessageText.DeletedExperiment,
          HeaderMessageType: MessageBarType.error
        });
      }
      else{
        var currActivityDate = moment(Experiment.StartDate);
        var currDate = moment(new Date());
        var diffDates = currDate.diff(currActivityDate,'days');
        //validate start date
        if(diffDates>0){
          this.setState({
            DisableForm: true,
            HeaderMessage: MessageText.NoEditPastExp,
            HeaderMessageType: MessageBarType.error
          });
        }
        //validate permissions
        if(!this.context.isAdmin && !Utils.strEqualsCI(this.context.userName, Experiment.Owner[0].Email)){         
          this.setState({
            DisableForm: true,
            DisableAdd: true,
            DisableClone: true,
            HeaderMessage: MessageText.NoAdminExpOwner,
            HeaderMessageType: MessageBarType.error
          });
        }
      }
    }
  }
  setCurrentUserAsOwner = ():IUserPersonaField =>{
    let currUserAsOwner:IUserPersonaField = {} as IUserPersonaField;
    currUserAsOwner.DisplayName = this.context?.name;
    currUserAsOwner.Email = this.context?.userName;
    return currUserAsOwner;
  }
  getUserRole = (ownerEmail:string,actArr:IActivityGridModel[]):"ExpOwner"|"Admin"|"Actowner"|"None" =>{
    if(this.context?.isAdmin){
      return "Admin";
    }
    if(Utils.strEqualsCI(ownerEmail, this.context?.userName)){
      return "ExpOwner";
    }
    if(actArr.length>0){
      for (let actIndex = 0; actIndex < actArr.length; actIndex++) {
        const act = actArr[actIndex];
        if(Utils.strEqualsCI(act.BookedForEmail, this.context?.userName) || Utils.strEqualsCI(act.BookedByEmail, this.context?.userName)){
          return "Actowner";
        }
      }
    }
    return "None";
  }
  CheckIfTodayIsPastMinDate= ():boolean=>{
    var minDate = moment(AppConfig.ActivityForm.MinDate),
    currDate = moment();
    let diffDates = currDate.diff(minDate,'days');
    if( diffDates>0){
        return true;
    }
    return false;
}
  loadAddForm=()=>{   
    const {Experiment} = this.state;
    Experiment.Owner = [] as IUserPersonaField[];
    let curUserProfile = this.setCurrentUserAsOwner();
    Experiment.Owner.push(curUserProfile);
    Experiment.StartDate = new Date(now);
      let CheckIfTodayIsPastMinDate = this.CheckIfTodayIsPastMinDate();
      if(!CheckIfTodayIsPastMinDate){
        Experiment.StartDate = new Date(formatActivityDate(AppConfig.ActivityForm.MinDate));
      }
      this.setState({
        Mode: "add",
        Experiment,
        PlanexLoading: false,
        UserRole:this.getUserRole(curUserProfile.Email,[])
      });  
  }
  loadEditForm=(expId: number)=>{
    const promises=[];
    promises.push(ExperimentService.getExperiment(expId));
    promises.push(ExperimentService.getActivitiesForExp(expId));
    promises.push(getShiftData());
    promises.push(SyncService.getExpActivitySyncForUser(expId,this.context?.userName));
    let userRole: "None" | "ExpOwner" | "Admin" | "Actowner" = "None";
    Promise.all(promises)
        .then(responses => {     
          if(responses[0]!=null){
            if (responses && responses.length > 0) {            
              let _experiment = responses[0] as IExperiment;
              let _activities: IActivityGridModel[] = [];
              if(responses.length>1){
                _activities = responses[1] as IActivityGridModel[];  
                userRole = this.getUserRole(_experiment.Owner[0].Email,_activities);
                let shiftsRes:IActivityShift[]   = responses[2] as IActivityShift[];
                let shifts:IActivityShift[] = _.filter(shiftsRes,{StatusId:AppConfig.ActivityForm.ActiveStatusId}) as IActivityShift[];
                let actSyncRes:IActivitySync[] = responses[3] as IActivitySync[];
                let syncActIdArr:number[] =  actSyncRes.length>0?_.map(actSyncRes,'ActivityId'):[];                       
                // let activityIdArr = _.uniq(_.map(_activities,'ActivityId'));
                ActivityGridService.getGridShiftLocationAvaliability()
                .then((sLAData) =>{
                    this.setState({
                      PlanexLoading: false,
                      FormActionLoading:false,
                      InfoMessage:'',
                      Experiment: _experiment,
                      UserRole:userRole,
                      sLAData:sLAData,
                      ShiftTimmingsArr:shifts,
                      ExperimentActivities: this.reduecActivityArray([..._activities],sLAData),
                      PreviousStartDate: _experiment.StartDate,
                      PreviousProjectId:_experiment.ProjectId,
                      ActivitySyncData:actSyncRes,
                      ActivitySyncIdArr:syncActIdArr
                    }, () => {
                      this.validateForm();
                    });
                });
              }
            }
          }
          else{
            this.setState({
              PlanexLoading: false,
              DisableForm: true,
              HeaderMessage: MessageText.NoExperimentWithId,
              HeaderMessageType: MessageBarType.error
            });
          }
        })
        .catch(error => {
          console.log(error);
      });
  }
  reduecActivityArray=(activities: IActivityGridModel[],slaData:number[])=>{
    let groupedArr: IActivityGridModel[]=[];
    const {ActivitySyncData} =  this.state;  
    if(activities.length>0){      
      activities.forEach((item, index)=>{    
          if(slaData.indexOf(item.ActivityId)!== -1){
              item.IsOverBooked = true;
          }
          else{
              item.IsOverBooked = false;
          }
          if(item.ShiftId === AppConfig.ActivityForm.TBDRejectShiftId){
            item.isTbdSelected = true;
          }
          let groupedItem = groupedArr.filter(i => {return i.ActivityId==item.ActivityId});
          if(groupedItem.length !== 1){
              item.SelectedShifts = this.checkAndGetShiftId(item,item.ShiftId);
              item.SelectedLocations = this.checkAndGetLocId(item,item.LocationId);
              // item.isPushed = syncActIdArr.indexOf(item.ActivityId) !== -1;
              groupedArr.push(item);
          }
          else{
            groupedItem[0].SelectedShifts = this.checkAndGetShiftId(groupedItem[0],item.ShiftId);
            groupedItem[0].SelectedLocations = this.checkAndGetLocId(groupedItem[0],item.LocationId);
            groupedItem[0].ShiftName = this.checkAndReduceField(groupedItem[0],"ShiftName",item.ShiftName);
            groupedItem[0].ShiftShortName = this.checkAndReduceField(groupedItem[0],"ShiftShortName",item.ShiftShortName);
            groupedItem[0].LocationName = this.checkAndReduceField(groupedItem[0],"LocationName",item.LocationName);
            groupedItem[0].EquipmentName = this.checkAndReduceField(groupedItem[0],"EquipmentName",item.EquipmentName);
            // groupedItem[0].isPushed = syncActIdArr.indexOf(item.ActivityId) !== -1;
          }
      });      
    }
    return this.sortActivities(groupedArr);  
  }      
  checkAndGetShiftId = (item: IActivityGridModel, shiftId: number): number[]=>{ 
    let shiftArr:number[] = item.SelectedShifts?item.SelectedShifts:[];
    if(shiftArr.indexOf(shiftId) === -1){
      shiftArr.push(shiftId);
    }
    return shiftArr;
  }   
  checkAndGetLocId = (item: IActivityGridModel, shiftId: number): number[]=>{ 
    let shiftArr:number[] = item.SelectedLocations?item.SelectedLocations:[];
    if(shiftArr.indexOf(shiftId) === -1){
      shiftArr.push(shiftId);
    }
    return shiftArr;
  }   
  checkAndReduceField=(item: IActivityGridModel, fieldName: string, fieldValue: string): string=>{    
    switch(fieldName){
      case "ShiftName": {        
        return (item.ShiftName != null && item.ShiftName.split("|").indexOf(fieldValue)<0) ? item.ShiftName + "|" + fieldValue : item.ShiftName;
      }
      case "ShiftShortName": {        
        return (item.ShiftShortName != null && item.ShiftShortName.split("|").indexOf(fieldValue)<0) ? item.ShiftShortName + "|" + fieldValue : item.ShiftShortName;
      }
      case "LocationName": {
        return (item.LocationName != null && item.LocationName.split("|").indexOf(fieldValue)<0) ? item.LocationName + "|" + fieldValue : item.LocationName;
      }
      case "EquipmentName": {
        return (item.EquipmentName != null && item.EquipmentName.split("|").indexOf(fieldValue)<0) ? item.EquipmentName + "|" + fieldValue : item.EquipmentName;
      }
      default:
        return "";
    }
  }
  checkIfUserHasNoOwnerShip = (selectedAct: IActivityGridModel) => {
    var flag:boolean = false;
    if(selectedAct.Owner !== this.context?.userName){
      if(!Utils.strEqualsCI(selectedAct.BookedForEmail, this.context?.userName) && !Utils.strEqualsCI(selectedAct.BookedByEmail, this.context?.userName)){
        flag = true;
      }
    }
    return flag;
  }
  _onItemSelectionChange = () => {
    const {ExperimentActivities} = this.state;    
    let selIndices = this._selection.getSelectedIndices();        
    let _activities: IActivityGridModel[]=[];
    let _disableDelete = false;
    let _disableClone = false;
    selIndices.map((item: number)=>{
      _activities.push(ExperimentActivities[item]);
      let _currActivityDate = moment(ExperimentActivities[item].ActivityDate);
      let _currDate = moment(new Date());
      let _diffDates = _currDate.diff(_currActivityDate,'days');
      let IsRelatedToAct:boolean = this.checkIfUserHasNoOwnerShip(ExperimentActivities[item]);
      if(_diffDates > 0 || IsRelatedToAct){
        _disableDelete = true;
      }
    });
    if(_activities.length === 1){
      _disableClone = this.checkIfUserHasNoOwnerShip(ExperimentActivities[selIndices[0]]);
      this.setState({
        SelectedActivities: _activities,
        DisableDelete: _disableDelete,
        DisableClone:_disableClone
      });
    }else{
      this.setState({
        SelectedActivities: _activities,
        DisableDelete: _disableDelete
      });
    }
  }
  onChangeExperimentName=(ev: any)=>{
    const _experiment = this.state.Experiment;
    _experiment.Name = ev.target.value;
    this.setState({Experiment: _experiment, ExperimentNotSaved: true});
  }
  onProjectChange=(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number)=>{
    if(option){
      const _experiment = this.state.Experiment;
      const  _previousProjectId = _experiment.ProjectId;
      _experiment.ProjectId = parseInt(option.key.toString());
      this.setState({Experiment: _experiment,PreviousProjectId: _previousProjectId, ExperimentNotSaved: true});
    }
  }
  onSelectExpDate = (updatedDate:any) => {    
    if (updatedDate){
      const _experiment = this.state.Experiment;
      const _previousStartDate = _experiment.StartDate;
      _experiment.StartDate = updatedDate as Date;
      this.setState({
        Experiment: _experiment,
        PreviousStartDate: _previousStartDate,
        ExperimentNotSaved: true
      });
    }
  };
  onSelectExperimentOwner = (items:IUserPersonaField[], property:string) => {   
      const _experiment = this.state.Experiment;
      _experiment.Owner = items.length>0?items:[];
      this.setState({Experiment: _experiment, ExperimentNotSaved: true});
  }
  onChangeExperimentDesc=(ev: any)=>{
    const _experiment = this.state.Experiment;
    _experiment.Description = ev.target.value;
    this.setState({Experiment: _experiment, ExperimentNotSaved: true});
  }
  onDeleteExperiment=(ev: any)=>{
    let _promptValue = window.confirm(MessageText.DeleteExptConfirm);  
    if(_promptValue){
      
    }
  }
  onCloseExperiment=(ev: any)=>{
    const {ExperimentNotSaved,ShowAddActivity} = this.state;
    if(ExperimentNotSaved || ShowAddActivity ==="Edit" || ShowAddActivity ==="Add"){ 
      this.setState({ 
        ShowDialog:"CloseExpForm"
      });
    }  
    else{
      this.setState({RedirectUri: "/Home"});
    }  
  }
  onSaveExperiment=(ev: any)=>{
    ev.preventDefault();
    const mode = this.state.Mode; 
    let userRole: "None" | "ExpOwner" | "Admin" | "Actowner" = "None";
    if(Utils.strEqualsCI(mode, "ADD")){
      const {Experiment} = this.state;
      let validatonObj:IValidationObj = validateExpFields(Experiment);
      if (validatonObj.isValid) {
        this.setState({
          SaveExpProcessing: true,
        });
        userRole = this.getUserRole(Experiment.Owner[0].Email,[]);
        ExperimentService.addExperiment(Experiment).then((data) => {
          const _experiment = this.state.Experiment;
          _experiment.Id = data.output.ExperimentId;
          _experiment.StatusId = 1;
            this.setState({
              Experiment: _experiment,
              EID: _experiment.Id,
              Mode: "EDIT",
              UserRole:userRole,
              SaveExpProcessing: false,
              ExperimentErrMsgs: validatonObj.ErrorMsgs,
              InfoMessage: MessageText.NoActivities,
              RedirectUri: "/PlanExperiment?id=" + data.output.ExperimentId,
              TopLabel: "Edit Experiment",
              ExperimentNotSaved: false,
              HeaderMessage: MessageText.ExptDetailsSaved,
              HeaderMessageType: MessageBarType.success,
              // FormId:uuidv4()
            });
        })
      .then(()=>{
        this.validateForm();
        });
      }
      else{
          this.setState({
            ExperimentErrMsgs: validatonObj.ErrorMsgs,
          });
      }
    }
    else if(Utils.strEqualsCI(mode, "EDIT")){
      const {Experiment} = this.state;
      let validatonObj:IValidationObj = validateExpFields(Experiment);
      if (validatonObj.isValid) {
        this.setState({
          SaveExpProcessing: true,
        });        
        Experiment.UpdateActivities = Experiment.StartDate !== this.state.PreviousStartDate || Experiment.ProjectId !== this.state.PreviousProjectId;
        ExperimentService.updateExperiment(Experiment).then((data) => {
          console.log(data);
          if(data.length > 0){
            const {sLAData} = this.state;
            let _activities = data[0] as IActivityGridModel[];
            userRole = this.getUserRole(Experiment.Owner[0].Email,_activities);
            this.reloadActivityGrid();
              this.setState({
                Mode: "EDIT",
                SaveExpProcessing: false,
                ExperimentNotSaved: false,
                UserRole:userRole,
                ExperimentActivities: this.reduecActivityArray([..._activities],sLAData),
                FormId:uuidv4()
              });
            }
            else{
              userRole = this.getUserRole(Experiment.Owner[0].Email,[]);
              this.setState({
                Mode: "EDIT",
                SaveExpProcessing: false,
                ExperimentNotSaved: false,
                UserRole:userRole,
              });
            } 
      })
      .then(()=>{
        this.validateForm();
        });
      }
    } 
       
  }  
  onDismissHeaderMessage=(ev: any)=>{
    this.setState({HeaderMessage: ""});
  }
  onClickDeleteExp = () =>{
    let _hideDeleteDialog = this.state.HideDeleteDialog;
    this.setState({
      HideDeleteDialog: !_hideDeleteDialog,
      DeleteNotes: ""
    }) 
  }
  onChangeDeleteNotes=(ev: any)=>{
    this.setState({DeleteNotes: ev.target.value})
  }
  onClickDeleteConfirmation= () =>{
    const {DeleteNotes,Experiment, ActivitySyncIdArr} = this.state;
    let currUser =  this.context?.userName;
    if(DeleteNotes.trim().length > 0){
        let notesWithUserProfile = DeleteNotes +" - Deleted by " + currUser; 
        ExperimentService.deleteExperiment(Experiment.Id, notesWithUserProfile)
        .then(response => {
          this.reloadActivityGrid();
          if(ActivitySyncIdArr.length>0){
            ExperimentActOutlookSync.removeAllActSync(Experiment.Id, currUser,false).then(()=>{
              this.setState({
                DeleteNotes:"", 
                ShowAddActivity:"None",
                HideDeleteDialog:true,  
                DisableForm: true,
                HeaderMessage: "Experiment deleted successfully. " + MessageText.DeletedExperiment,
                HeaderMessageType: MessageBarType.error
              });
            });
          }
          else{
            this.setState({
              DeleteNotes:"", 
              ShowAddActivity:"None",
              HideDeleteDialog:true,  
              DisableForm: true,
              HeaderMessage: "Experiment deleted successfully. " + MessageText.DeletedExperiment,
              HeaderMessageType: MessageBarType.error
            });
          }
        }).catch(error => {
            this.setState({
                HideDeleteDialog: true,
                HeaderMessage:AppConfig.ActivityForm.DeleteErrorMessage + error
            });
            console.log(error);
        });         
    }
    else {
      this.setState({HideDeleteDialog:false, DeleteErrorMessage: MessageText.DeleteNotesError});
    }
  }
  onClickCloneExperiment=(ev: any)=>{
    const {HideCloneDialog} = this.state;
    this.setState({
      HideCloneDialog:!HideCloneDialog
    });
  }
  onClickConfirmCloneExperiment=(ev: any)=>{
    const {HideCloneDialog, Experiment} = this.state;
    this.setState({
      HideCloneDialog:!HideCloneDialog,
      FormActionLoading:true,
      InfoMessage:MessageText.CloningExpLoaderTxt
    });    
    ExperimentService.cloneExperiment(Experiment.Id, this.context.userName, this.context.name).then((res)=>{
      const _url = "/PlanExperiment?id=" + res.CloneExpId +"&ParentId="+ Experiment.Id;
      Experiment.Owner = [{
        DisplayName:this.context.name,
        Email:this.context.userName
      }];
      this.setState({
        Experiment,
        FormId:uuidv4(),
        RedirectUri: _url,
        DisableForm:false,
        DisableAdd:false,
        InfoMessage:'Loading...',
        ShowAddActivity:"None",
        HeaderMessageType:MessageBarType.success,
        HeaderMessage:MessageText.CloneSuccessMessage});
      });
  }
  handleActSyncSave = (actSyncArr:any[],action:string) =>{
    const{ActivitySyncData, ActivitySyncIdArr} = this.state;
    this._removeFromOutlook = false;
    this._pushToOutlook = false;
    if(action === "Push"){
      let updatedArr:IActivitySync[] = ActivitySyncData.concat(actSyncArr);
      let updatedSyncIdArr:number[] = ActivitySyncIdArr; 
      updatedSyncIdArr.push(actSyncArr[0].ActivityId);
      this.setState({
        ActivitySyncData:updatedArr, 
        ActivitySyncIdArr:updatedSyncIdArr
      });
    }
    if(action ==="Delete"){
      if(actSyncArr.length>0){
        let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== actSyncArr[0].ActivityId});
        let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== actSyncArr[0].ActivityId});
        this.setState({
          ActivitySyncData:updatedArr,
          ActivitySyncIdArr:updatedIdArr 
        });
      }
    }
    if(action ==="Update"){
      if(actSyncArr.length>0){
        let updatedArr:IActivitySync[] = ActivitySyncData.filter(o=>{ return o.ActivityId !== actSyncArr[0].ActivityId});
        let updatedIdArr:number[] = ActivitySyncIdArr.filter(o=>{ return o !== actSyncArr[0].ActivityId});
        updatedArr = updatedArr.concat(actSyncArr);
        updatedIdArr.push(actSyncArr[0].ActivityId); 
        this.setState({
          ActivitySyncData:updatedArr,
          ActivitySyncIdArr:updatedIdArr 
        });
      }
    }
  }
  getActivityColumns=():IColumn[]=>{
    let _columns = [      
      {
        key: "ToggleButton",
        name: "",
        fieldName: "",
        minWidth: 80,
        maxWidth: 90,
        onRender: (item: IActivityGridModel) => {
          return  <OutlookButtonForGrid handleActSyncSave={this.handleActSyncSave} Shifts = {this.state.ShiftTimmingsArr} item={item} syncData={_.filter(this.state.ActivitySyncData,{ActivityId:item.ActivityId})} isPushed ={this.state.ActivitySyncIdArr.indexOf(item.ActivityId)!==-1} />
        },
      },
      {
        key: "IsOverBooked",
        name: "",
        fieldName: "IsOverBooked",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item: IActivityGridModel) => {
            return item.IsOverBooked?(
              <div style={{ margin:"-8px 0px" }} >
                  <Icon
                      title ={"Over Booked"}
                      style={{fontSize:30, fontWeight:600, color: "red", margin:"-1px 0px" }}
                      iconName="StatusTriangleExclamation"
                      className="iconPadClasss"
                  />
              </div>
            ):"";
        }
      },
      {
        key: "ActivityDate",
        name: "Date",
        fieldName: "ActivityDate",
        minWidth: 90,
        maxWidth: 110,
        onRender: (item: IActivityGridModel) => {
          return <div>{moment.utc(item.ActivityDate).format("MM/DD/YYYY")}</div>;
        },
      },  
    
      {
        key: "ActivityName",
        name: "Activity Name",
        fieldName: "ActivityName",
        minWidth: 200,
        maxWidth: 250,
      },      
      {
        key: "ShiftName", 
        name: "Shift(s)",
        fieldName: "ShiftName",
        minWidth: 110,
        maxWidth: 120,
        onRender: (item: IActivityGridModel) => {
          if(!item.ShiftName){
              return <span></span>;
          }
          else{
              let splitArr:any[] = item.ShiftShortName?.split('|');
              let shiftChunks =  _.chunk(splitArr,2);
              return (<div>
                  { shiftChunks?.map((shift,key)=>{return <div key={key}>{shift.join(", ") + (shiftChunks?.length !== key + 1? ",":"")}</div>})}
                  </div>
              );
          }
        }
      },
      {
        key: "LocationName",
        name: "Location(s)",
        fieldName: "LocationName",
        minWidth: 130,
        maxWidth: 150,
        onRender: (item: IActivityGridModel) => {
          if(!item.LocationName){
              return <span></span>;
          }
          else{
              return (<div>
                  { item.LocationName.split("|").map((eq,key)=>{return <div key={key}>{eq}</div>})}
                  </div>
              );
          }
        }
      },      
      {
        key: "EquipmentName",
        name: "Equipment(s)",
        fieldName: "EquipmentName",
        minWidth: 130,
        maxWidth: 150,
        onRender: (item: IActivityGridModel) => {
          if(!item.EquipmentName){
              return <span></span>;
          }
          else{
              return (<div>
                  { item.EquipmentName.split("|").map((eq,key)=>{return <div key={key}>{eq}</div>})}
                  </div>
              );
          }
        }
      },
      {
        key: "BookedForName",
        name: "Person",
        fieldName: "BookedForName",
        minWidth: 200,
        maxWidth: 220,
        onRender: (item: IActivityGridModel) => {
          if(Utils.strEqualsCI(item.BookedByEmail,item.BookedForEmail)){
          return <span>{item.BookedForName}</span>;
          }
          else{
              let iconTitle:string ="Booked By: "+item.BookedByName +"\nBooked For: " +item.BookedForName;
              return (<div style={{display:"flex"}} title={iconTitle} >
                  <FontIcon iconName="PeopleAlert" style={{color: "black",fontSize:16,fontWeight:400}}/>
                  <div style={{marginLeft:3}}>{item.BookedForName}</div>
                  </div>
              );
          }
        }
      },
      {
        key: "ActivityDescription",
        name: "Description",
        fieldName: "ActivityDescription",
        minWidth: 200,
        maxWidth: 250,
      },
    ];
    return _columns;
  }
}

