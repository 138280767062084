import * as React from 'react';
import {DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
import {TextField, ITextFieldStyles,ITextFieldStyleProps} from 'office-ui-fabric-react/lib/TextField';
import { IActivitySync, INewOutlookFormProps, INewOutlookFormState, INewOutlookItem, IOutlookBody, IOutlookDateTime, IOutlootEvent, IShiftTimings } from '../interfaces/IOutlookSync';
import { IActivityLocation, IActivityShift } from '../interfaces/IActivityForm';
import {Spinner,SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import _ from 'lodash';
import AppConfig from '../../../Constants';
import { formatActivityDate } from '../ActivityService';
import { SyncService } from './SyncService';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { ExperimentActOutlookSync } from './ExperimentActOutlookSync';

const uuidv4 = require('uuid/v4');


const txtFieldClass:Partial<ITextFieldStyleProps> & Partial<ITextFieldStyles> = {
    wrapper: {
        width: '100%',
        display: 'block',
        selectors:{
            'disabled':{
                color:'black'
            }
        }
    },
    root: {
        width: '100%',
    },
    subComponentStyles:{
        label:{
            color:'black!important'
        }
    }
};
export default class NewOutlookForm extends React.Component<INewOutlookFormProps, INewOutlookFormState> {     
    constructor(props: INewOutlookFormProps) {
        super(props);
        this.state = {
            OutLookItem:{
                Subject:"",
                Body:"",
                LocationsStr:"",
                TransactionId:"",
                ShiftTimings:[] as IShiftTimings[],
                ShiftTimingsClubbed:[] as IShiftTimings[]
            },
            isSaving:false,
            showMsg:false,
            Msg:"",
            MsgType:MessageBarType.info
            

        }
    }
    public componentDidMount = () =>{
        const {ActivityDetails,Locations,Shifts} = this.props;
        let locationString:string  = '';
        if(Locations){
            locationString= this.getLocationStr(ActivityDetails.selectedLocations,Locations);
        }else{
            locationString= this.props.LocationNames?ExperimentActOutlookSync.getLocationFromStr(this.props.LocationNames):'';
        }
        let shiftTimings = SyncService.getShiftTimingsArr( ActivityDetails.activityDate, ActivityDetails.selectedShiftIds, Shifts);
        this.setState({
            OutLookItem:{
                Subject:ActivityDetails.activityName +" ("+locationString+")",
                Body: ActivityDetails.activityDescription,
                LocationsStr:locationString,
                TransactionId:uuidv4(),
                ShiftTimings: shiftTimings,
                ShiftTimingsClubbed:SyncService.clubShiftTimings(shiftTimings)
            }
        });
    }    
    private getLocationStr(selectedLocations: number[], Locations: IActivityLocation[]): string {
        let locationArr:string[] = []; 
        for (let index = 0; index < selectedLocations.length; index++) {
            const selectedLocId = selectedLocations[index];
            let filterLocationByIdArr:IActivityLocation[] = _.filter(Locations,{LocationId:selectedLocId});
            if (filterLocationByIdArr && filterLocationByIdArr.length>0) {
                locationArr.push(filterLocationByIdArr[0].LocationName);
            }            
        }
        return locationArr.join(", ");
    }    
    private handleOnTitleChange = (ev:any) =>{
        let val:string = ev.target.value;
        const {OutLookItem}:any = this.state;
        OutLookItem.Subject = val;
        this.setState({
            OutLookItem
        });
    } 
    private handleOnDescChange = (ev:any) =>{
        let val:string = ev.target.value;
        const {OutLookItem}:any = this.state;
        OutLookItem.Body = val;
        this.setState({
            OutLookItem
        });
    } 
    private generateCalEvents(OutLookItem: INewOutlookItem): IOutlootEvent[] {
        let eventArr:IOutlootEvent[] = [] as IOutlootEvent[]; 
        let tzstr:any = process.env.REACT_APP_CAL_TIME_ZONE;
        let itemURL:string = "<p><a href='"+ process.env.REACT_APP_BODY_HREF_URL+ this.props.ActivityDetails.Id+"'>Click here<a/> to navigate to the activity in SWFT application</p>" ;
        var defaultTxt:string = itemURL + process.env.REACT_APP_DEFAULT_BODY_APPEND_MSG;
        // console.log(defaultTxt);
        let shifts:IShiftTimings[] = OutLookItem.ShiftTimingsClubbed;
        for (let index = 0; index < shifts.length; index++) {
            const shift = shifts[index];
            let obj:IOutlootEvent = {} as IOutlootEvent;
            obj.body = {} as IOutlookBody;
            obj.body.contentType = "HTML";
            obj.body.content = OutLookItem.Body + defaultTxt;
            obj.subject = OutLookItem.Subject;
            obj.transactionId = uuidv4();
            let start = {} as IOutlookDateTime;
            let end = {} as IOutlookDateTime;
            start.dateTime = shift.StartTime;
            start.timeZone = tzstr;
            end.dateTime = shift.EndTime;
            end.timeZone = tzstr;
            obj.start = {...start};
            obj.end = {...end};
            eventArr.push({...obj});
        }
        return eventArr;
    }
    private checkIfResponseIsSuccess = (calRes:any) =>{
       try {
        if (calRes.Status.toString() === "201") {
            return true;
        }    
       } catch (error) {
           return false;
       }          
        return false;
    }
    private formActSyncObj = (calResponse: any): any =>{
        let actSyncObj:IActivitySync = {} as IActivitySync;
        const {OutLookItem} = this.state;
        if(calResponse){
            try {
                actSyncObj.ActivityId = this.props.ActivityDetails.Id?this.props.ActivityDetails.Id:0;
                actSyncObj.ExperimentId = this.props.ActivityDetails.ExperimentId?this.props.ActivityDetails.ExperimentId:null;
                actSyncObj.EventId = calResponse.response.id;
                actSyncObj.EventTitle = calResponse.response.subject;
                actSyncObj.EventBody = OutLookItem.Body;
                actSyncObj.EventStartTime = calResponse.body.start.dateTime;
                actSyncObj.EventEndTime = calResponse.body.end.dateTime;;
                actSyncObj.EventSyncedBy = calResponse.response.organizer.emailAddress.address;
                actSyncObj.TransactionId = calResponse.response.transactionId;
                actSyncObj.StatusId = 1;
                return actSyncObj;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return false;
    }    
    private handlePushBtnClick = () =>{
        const {OutLookItem} = this.state;
        if(!OutLookItem.Subject){
            this.setState({
                showMsg:true,
                Msg:"Please fill the required fields.",
                MsgType:MessageBarType.error
            });
        }
        else{
            this.callForPush(OutLookItem);
        }
    }
    callForPush = (OutLookItem:INewOutlookItem) =>{
        this.setState({isSaving:true,showMsg:false});
        let calendarDetailsArr:IOutlootEvent[] = this.generateCalEvents(OutLookItem);        
        SyncService.CreateOutlookEvent(calendarDetailsArr).then((calRes:any[]) =>{
            console.log(calRes);
            var createdActSynRec:number = 0;
            var allCreatedRecords:number = calRes.length;
            let actSyncArr:IActivitySync[]  = []; 
            for (let index = 0; index < allCreatedRecords; index++) {
                const eachCalResponse = calRes[index];
                if(this.checkIfResponseIsSuccess(eachCalResponse)){
                    //create
                    let actSyncObj:IActivitySync = this.formActSyncObj(eachCalResponse);
                    if(actSyncObj){
                        SyncService.createActivitySyncRecord(actSyncObj).then((actSyncRecord:any) => {
                            createdActSynRec++;
                            actSyncArr.push(actSyncObj);
                            if(createdActSynRec === calRes.length){
                                this.setState({isSaving:false});
                                this.props.handleActSyncSave(actSyncArr,"Push");
                            } 
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                isSaving:false,
                                showMsg:true,
                                Msg:"Push to Outlook failed. Please try later.",
                                MsgType:MessageBarType.error
                            });
                        });
                    }
                }else{
                    console.log(calRes);
                    this.setState({
                        isSaving:false,
                        showMsg:true,
                        Msg:"Push to Outlook failed. Please try later.",
                        MsgType:MessageBarType.error
                    });
                }
            }                      
        }).catch((err: any) => {
            console.log(err);
            this.setState({
                isSaving:false,
                showMsg:true,
                Msg:"Push to Outlook failed. Please try later.",
                MsgType:MessageBarType.error
            });
        });
    }
   
    private handleCancelBtnClick = () =>{
        this.props.handleFormCancelClick();
    }
    private renderMsgbar(Msg: string, MsgType: MessageBarType) {
        return(
            <MessageBar messageBarType={MsgType} className={"errorMsgInfo"}>
                {Msg}
            </MessageBar>
        );
    }
    public render() {
        const {OutLookItem,isSaving,showMsg,Msg,MsgType} = this.state;
        const turnOffSync = {iconName:"RemoveEvent"};
        return (
        <>
            <TextField
                label="Event Subject"
                required
                name="Subject"
                placeholder="Event Subject"
                value={OutLookItem.Subject}
                errorMessage={!OutLookItem.Subject? "Event Subject is required filed.":''}                
                onChange={this.handleOnTitleChange}
                styles={txtFieldClass}/>
            <TextField
                label="Event Description"
                multiline={true}
                name="Body"
                placeholder="Event Description"
                value={OutLookItem.Body}
                onChange={this.handleOnDescChange}
                styles={txtFieldClass}/>
            {showMsg && (this.renderMsgbar(Msg,MsgType))}
            <div style={{marginTop:20,display:"flex",justifyContent:"space-between"}}>                   
                    <div style={{display:"flex",justifyContent:"space-between"}}> 
                        {isSaving &&( 
                        <Spinner
                            label={"Saving"}
                            ariaLive="assertive"
                            labelPosition="left"
                            size={SpinnerSize.small}
                        />)}
                        <PrimaryButton text="Push" iconProps={{iconName:"Save"}} disabled={showMsg} onClick={this.handlePushBtnClick}  style={{margin:"0 5px"}}/>
                        <DefaultButton text="Cancel" iconProps={{iconName:"Cancel"}} onClick={this.handleCancelBtnClick} />
                    </div>
            </div>
        </>
        );
    }
    
}
