import * as React from 'react';
import  ActivityGridWrapper from '../Components/ActivityGrid/ActivityGridWrapper';
import { RouteComponentProps } from 'react-router-dom';
import { UserInfoContext, ActivityGridContext } from '../Main';
import { IFilterData } from '../Components/ActivityGrid/ActivityGridFilters';
import { IUserPersonaField } from '../Components/Activity/interfaces/IActivityForm';
import _ from 'lodash';
import ExperimentGrid from '../Components/ActivityGrid/ExperimentGrid';
const uuidv4 = require('uuid/v4');


export interface IMyActivitesProps {
    handleRedirection:(selectedKey:any)=>void;
    handleFilterChange:(filterData:IFilterData)=>void;
    filterValues:IFilterData;
}
export interface IMyActivitesState {
  FilterValues:IFilterData,
  filterKey:any;
}

export class MyActivites extends React.Component<RouteComponentProps & IMyActivitesProps, IMyActivitesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      FilterValues:{} as IFilterData,
      filterKey:uuidv4()
    }
    this.props.handleRedirection('5');
  }
  componentDidMount = () =>{
    const {filterValues} = this.props;
    this.setState({
      FilterValues:filterValues,
      filterKey:uuidv4()
    });
  }
  componentDidUpdate = (prevProps:IMyActivitesProps,prevState:IMyActivitesState)=>{
    const {filterValues} = this.props;
    let isRealUpdate:boolean = false;
    if(prevProps.filterValues.hidePastAct !== filterValues.hidePastAct){           
      isRealUpdate= true;
    }
    if(prevProps.filterValues.FromDate !== filterValues.FromDate){           
      isRealUpdate= true;
    }
    if(prevProps.filterValues.ToDate !== filterValues.ToDate){           
        isRealUpdate= true;
    }
    if(this.CheckProjectField(prevProps.filterValues.ProjectId,filterValues.ProjectId)||this.CheckProjectField(filterValues.ProjectId, prevProps.filterValues.ProjectId)){
        isRealUpdate= true;
    }
    if(this.CheckPersonsInField(prevProps.filterValues.User,filterValues.User) || this.CheckPersonsInField(filterValues.User, prevProps.filterValues.User)){
        isRealUpdate= true;
    }
    if(isRealUpdate){
        this.setState({
            FilterValues:{...filterValues},
            filterKey:uuidv4()
        });
    }
}
CheckPersonsInField = (prevPeople :IUserPersonaField[]|null,CurrPeople: IUserPersonaField[]|null) =>{
  let flag:boolean = false;
  var currPeopleEmail:string[] = CurrPeople?_.map(CurrPeople,'Email'):[];
  if(prevPeople){
      for (let people = 0; people < prevPeople.length; people++) {
          const prevPerson:IUserPersonaField = prevPeople[people];
          if(currPeopleEmail.indexOf(prevPerson.Email) === -1){
              flag = true;
          }
      }
  }
  return flag;
}
CheckProjectField = (prevProject :string[]|null,CurrProject: string[]|null) =>{
  let flag:boolean = false;
  if(prevProject){
      for (let Project = 0; Project < prevProject.length; Project++) {
          const prevPrjctId:string = prevProject[Project];
          if(CurrProject?.indexOf(prevPrjctId) === -1){
              flag = true;

          }
      }
  }
  return flag;
}
  handleFilterChange = (FilterValues:IFilterData) =>{
    this.setState({
      FilterValues
    });
    this.props.handleFilterChange(FilterValues);
  }
  public render() {
    const {FilterValues,filterKey} = this.state;
    return (
      <UserInfoContext.Consumer>
        {(value) => (
          <div style={{marginBottom:20}}>
            <ActivityGridContext.Provider value={FilterValues}>
              <ActivityGridWrapper key={filterKey} userContext={value} filterProps={FilterValues}
              handleFilterChange ={this.handleFilterChange}/> 
            </ActivityGridContext.Provider>
            <ExperimentGrid/>
          </div>
        )}
        
      </UserInfoContext.Consumer>
    );
  }
}
