import { CommandButton, HighContrastSelector, IButtonStyles, IconButton, IContextualMenuProps, mergeStyleSets, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { IActivityGridModel } from '../../ActivityGrid/interfaces/IActivityGridModel';
import { IActivitySync, INewOutlookItem, IOutlookBody, IOutlookDateTime, IOutlootEvent, IShiftTimings } from '../interfaces/IOutlookSync';
import {Spinner,SpinnerSize} from 'office-ui-fabric-react/lib/Spinner';
import { ExperimentActOutlookSync } from './ExperimentActOutlookSync';
import { SyncService } from './SyncService';
import { IActivity, IActivityShift } from '../interfaces/IActivityForm';
import Chip from '@material-ui/core/Chip';
import NewOutlookForm from './NewOutlookForm';
import EditOutLookFrom from './EditOutLookFrom';
import _ from 'lodash';
import moment from 'moment';

const uuidv4 = require('uuid/v4');


export interface IOutlookButtonForGridProps {
    isPushed:boolean;
    item:IActivityGridModel;
    syncData:IActivitySync[];
    Shifts:IActivityShift[];
    // handleFormCancelClick:() =>void;
    handleActSyncSave:(actSyncArr:any[],action:string) =>void;
}

export interface IOutlookButtonForGridState {
    isPushed:boolean;
    isSaving:boolean;
    saveTxt:string;
    isSaved:boolean;
    actionTxt:string;
    isError:boolean;
    ErrorMsg:string;
    showForm:boolean;
    ActSyncArr:IActivitySync[];
}
const classNames = mergeStyleSets({
    menubutton: {
        height: '20px' 
    } 
});
const customSplitButtonStyles: IButtonStyles = {
    splitButtonMenuButton: { backgroundColor: 'inherit', width: 28, border: 'none',cursor: 'pointer' },
    splitButtonMenuIcon: { fontSize: '10px' },
    splitButtonDivider: {    
        borderLeft: '1px solid #c8c8c8',
        position: 'relative',
        right: 28,
    },
    root:{
        selectors:{
            'disabled':{
                color:'white',
                cursor:"not-allowed"
            },
        }
    },
    rootDisabled:{
        color:'white',
        cursor:"not-allowed"
    },
    splitButtonContainer: {
      selectors: {
        [HighContrastSelector]: { border: 'none' },
        'disabled':{
            color:'white',
            cursor:"not-allowed"
        },
    },
    cursor: 'pointer',
    }
  };
  const disabledSplitButtonStyles: IButtonStyles = {
    splitButtonMenuButton: { backgroundColor: 'inherit', width: 28, border: 'none',cursor:"not-allowed" },
    splitButtonMenuIcon: { fontSize: '10px' },
    splitButtonDivider: {    
        borderLeft: '1px solid #c8c8c8',
        position: 'relative',
        right: 28,
    },
    root:{
        selectors:{
            'disabled':{
                color:'white',
                cursor:"not-allowed"
            },
        }
    },
    rootDisabled:{
        color:'white',
        cursor:"not-allowed"
    },
    splitButtonContainer: {
      selectors: {
        [HighContrastSelector]: { border: 'none' },
        'disabled':{
            color:'white',
            cursor:"not-allowed"
        },
        cursor:"not-allowed"
    },    
    cursor:"not-allowed",
    }
  };
export default class OutlookButtonForGrid extends React.Component<IOutlookButtonForGridProps, IOutlookButtonForGridState> {
    constructor(props: IOutlookButtonForGridProps) {
        super(props);

        this.state = {
            isPushed:false,
            isSaving:false,
            saveTxt:"Saving",
            actionTxt:"Saved",
            isSaved:false,
            isError:false,
            ErrorMsg:'',
            showForm:false,
            ActSyncArr:[] as IActivitySync[]
        }
    }
    componentDidMount =()=>{
        const {isPushed} = this.props; 
        this.setState({
            isPushed
        });
    }
    componentDidUpdate =(prevProps:IOutlookButtonForGridProps, prevState:IOutlookButtonForGridState) =>{
        const {isPushed, syncData} = this.props; 
        if(prevProps.isPushed !== isPushed){
            this.setState({
                isPushed
            });
        }
        if(prevProps.syncData.length !== syncData.length){
            this.setState({
                isPushed
            });
        }
    }
    pushedEventBtns: IContextualMenuProps = {
        items: [
        {
            key: 'emailMessage',
            text: 'Remove From Outlook',
            title:'Remove From Outlook',
            iconProps: {iconName: 'RemoveEvent', style:{color:'black'}},
            onClick:() => {
                this.handleRemoveEventClick();
            }
        },
        {
            key: 'calendarEvent',
            text: 'Edit Outlook Event',
            title:'Edit Outlook Event',
            iconProps: {iconName: 'Edit',style:{color:'black'}},
            onClick:() => {
                this.handleFormOpenBtnClick();
            } 
        },
        ],
        directionalHintFixed: true,
    }
    nonpushedEventBtns: IContextualMenuProps = {
        items: [
        {
            key: 'addIcon',
            text: 'Push to Outlook',
            title:'Push to Outlook',
            iconProps: {iconName: 'AddEvent', style:{color:'black'}},
            onClick:() => {
                this.handleAddEventClick();
            }
        },
        {
            key: 'addIconForm',
            text: 'Push to Outlook (Form)',
            title:'Push to Outlook (Form)',
            iconProps: {iconName: 'AddEvent',style:{color:'black'}},
            onClick:() => {
                this.handleFormOpenBtnClick();
            }
        }
        ],
        directionalHintFixed: true,
    }
    public  handleDirectIconClick = () =>{
        const {isPushed}  = this.props;
        if(isPushed){
            this.handleRemoveEventClick();
        }else{
            this.handleAddEventClick();
        }
    }
    generateOutlookEventFromDetails(item: IActivityGridModel): INewOutlookItem {
        let newOutlookItem:INewOutlookItem ={} as INewOutlookItem;
        const {Shifts} = this.props;
        let locationString:string = ExperimentActOutlookSync.getLocationFromStr(item.LocationName);
        let shiftTimings = SyncService.getShiftTimingsArr( item.ActivityDate, item.SelectedShifts, Shifts);
        newOutlookItem.Subject = item.ActivityName +" ("+locationString+")";
        newOutlookItem.Body =  item.ActivityDescription;
        newOutlookItem.LocationsStr = locationString;
        newOutlookItem.TransactionId = uuidv4();
        newOutlookItem.ShiftTimings = shiftTimings;
        newOutlookItem.ShiftTimingsClubbed = SyncService.clubShiftTimings(shiftTimings);
        return newOutlookItem;
    } 
    private generateCalEvents(OutLookItem: INewOutlookItem): IOutlootEvent[] {
        let eventArr:IOutlootEvent[] = [] as IOutlootEvent[]; 
        let tzstr:any = process.env.REACT_APP_CAL_TIME_ZONE;
        let itemURL:string = "<p><a href='"+ process.env.REACT_APP_BODY_HREF_URL+ this.props.item.ActivityId+"'>Click here<a/> to navigate to the activity in SWFT application</p>" ;
        var defaultTxt:string = itemURL + process.env.REACT_APP_DEFAULT_BODY_APPEND_MSG;
        // console.log(defaultTxt);
        let shifts:IShiftTimings[] = OutLookItem.ShiftTimingsClubbed;
        for (let index = 0; index < shifts.length; index++) {
            const shift = shifts[index];
            let obj:IOutlootEvent = {} as IOutlootEvent;
            obj.body = {} as IOutlookBody;
            obj.body.contentType = "HTML";
            obj.body.content = OutLookItem.Body + defaultTxt;
            obj.subject = OutLookItem.Subject;
            obj.transactionId = uuidv4();
            let start = {} as IOutlookDateTime;
            let end = {} as IOutlookDateTime;
            start.dateTime = shift.StartTime;
            start.timeZone = tzstr;
            end.dateTime = shift.EndTime;
            end.timeZone = tzstr;
            obj.start = {...start};
            obj.end = {...end};
            eventArr.push({...obj});
        }
        return eventArr;
    }
    public  handleAddEventClick = () =>{
        const {item}  = this.props;
        this.setState({isSaving:true,saveTxt:"Saving",actionTxt:"Saved"});
        let newOutlookItem:INewOutlookItem = this.generateOutlookEventFromDetails(item);
        let outllokEvents: IOutlootEvent[] = this.generateCalEvents(newOutlookItem);
        this.callforCreate(outllokEvents, newOutlookItem);
    }
    private checkIfResponseIsSuccess = (calRes:any) =>{
        try {
         if (calRes.Status.toString() === "201") {
             return true;
         }    
        } catch (error) {
            return false;
        }          
         return false;
    }
    private formActSyncObj = (outlookItem:INewOutlookItem,calResponse: any): any =>{
        let actSyncObj:IActivitySync = {} as IActivitySync;
        if(calResponse){
            try {
                actSyncObj.ActivityId = this.props.item.ActivityId?this.props.item.ActivityId:0;
                actSyncObj.ExperimentId = this.props.item.ExperimentId?this.props.item.ExperimentId:null;
                actSyncObj.EventId = calResponse.response.id;
                actSyncObj.EventTitle = calResponse.response.subject;
                actSyncObj.EventBody = outlookItem.Body;
                actSyncObj.EventStartTime = calResponse.body.start.dateTime;
                actSyncObj.EventEndTime = calResponse.body.end.dateTime;;
                actSyncObj.EventSyncedBy = calResponse.response.organizer.emailAddress.address;
                actSyncObj.TransactionId = calResponse.response.transactionId;
                actSyncObj.StatusId = 1;
                return actSyncObj;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return false;
    }    
    private callforCreate = (calendarDetailsArr:IOutlootEvent[], outlookItem:INewOutlookItem ) =>{
    SyncService.CreateOutlookEvent(calendarDetailsArr).then((calRes:any[]) =>{
            console.log(calRes);
            var createdActSynRec:number = 0;
            var allCreatedRecords:number = calRes.length;
            let actSyncArr:IActivitySync[]  = []; 
            for (let index = 0; index < allCreatedRecords; index++) {
                const eachCalResponse = calRes[index];
                if(this.checkIfResponseIsSuccess(eachCalResponse)){
                    //create
                    let actSyncObj:IActivitySync = this.formActSyncObj(outlookItem,eachCalResponse);
                    if(actSyncObj){
                        SyncService.createActivitySyncRecord(actSyncObj).then((actSyncRecord:any) => {
                            createdActSynRec++;
                            actSyncArr.push(actSyncObj);
                            if(createdActSynRec === calRes.length){
                                this.setState({isSaving:false, isSaved:true,ActSyncArr:actSyncArr});
                                setTimeout(()=>{
                                this.setState({isSaved:false,showForm:false});
                                this.props.handleActSyncSave(actSyncArr,"Push");
                                },1500);
                            } 
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                isSaving:false,
                                isError:true,
                                ErrorMsg:'Push to Outlook failed. Please try later.'
                            });
                        });
                    }
                }else{
                    console.log(calRes);
                    this.setState({
                    isSaving:false,
                    isError:true,
                    ErrorMsg:"Push to Outlook failed. Please try later."
                    });
                }
            }                      
        }).catch((err: any) => {
            console.log(err);
            this.setState({
            isSaving:false,
            isError:true,
            ErrorMsg:"Push to Outlook failed. Please try later."
            });
        });
    }
    private checkIfDeleteResponseIsSuccess = (calRes:any) =>{
        try {
          for (let index = 0; index < calRes.length; index++) {
            const eachRes = calRes[index];
            if (eachRes.Status.toString() !== "204" && eachRes.Status.toString() !== "404") {
              return false;
            } 
          }
        } catch (error) {
          return false;
        }          
        return true;
    }
    public  handleRemoveEventClick = () =>{
        const {item,syncData}  = this.props;
        this.setState({isSaving:true,saveTxt:"Removing",actionTxt:"Removed"});
        let actId:number = item.ActivityId; 
        let eventSyncedBy:string = syncData[0].EventSyncedBy;
        SyncService.DeleteOutlookEvent(syncData).then((calRes:any) =>{
          if(this.checkIfDeleteResponseIsSuccess(calRes)){
            SyncService.purgeActivitySyncRecord(actId,eventSyncedBy).then(actSyncRecord => {
              console.log(actSyncRecord);
              this.setState({isSaving:false,isSaved:true});
              setTimeout(()=>{
                this.setState({isSaved:false,});
                this.props.handleActSyncSave([{"ActivityId":actId}],"Delete");
             },1500);
            })
            .catch((error:any) => {
              console.log(error);
              this.setState({
                isSaving:false,
                isError:true,
                ErrorMsg:"Removing from Outlook failed. Please try later."
              });
            });
          }
          else{
            this.setState({
                isSaving:false,
                isError:true,
                ErrorMsg:"Removing from Outlook failed. Please try later."
            });
          }
        }).catch((err: any) => {
             this.setState({
                    isSaving:false,
                    isError:true,
                    ErrorMsg:"Removing from Outlook failed. Please try later."
                });
            console.log(err);        
        });
    }
    public handleSavedChipDelete = () =>{
        const {ActSyncArr} =  this.state;
        this.setState({isSaved:false});
        this.props.handleActSyncSave(ActSyncArr,"Pushed");
    } 
    public handleFormOpenBtnClick = () =>{
        this.setState((previousState:IOutlookButtonForGridState)=>{
            return {
                showForm:!previousState.showForm
            };
        });
    }
    convertActDetails(item: IActivityGridModel): IActivity {
        let actDetailsObj:IActivity = {} as IActivity;
        actDetailsObj.ExperimentId = item.ExperimentId;
        actDetailsObj.Id = item.ActivityId;
        actDetailsObj.activityDate = item.ActivityDate;
        actDetailsObj.selectedShiftIds = item.SelectedShifts;
        actDetailsObj.activityName =  item.ActivityName;
        actDetailsObj.activityDescription = item.ActivityDescription;
        actDetailsObj.selectedLocations = item.SelectedLocations;
        return actDetailsObj;
    }
    renderSwitch = ():JSX.Element =>{
        const {isSaving,isSaved,actionTxt,saveTxt}  = this.state;
        const {isPushed,item, syncData}  = this.props;
        let menuprops = isPushed?this.pushedEventBtns:this.nonpushedEventBtns;
        let initialIcon = isPushed?{iconName:'RemoveEvent', style:{color:'black'}}:{iconName:'AddEvent', style:{color:'black'}};
        let ariaLabel = isPushed?'Remove From Outlook':'Push To Outlook';
        let title = isPushed?'Remove From Outlook':'Push To Outlook';
        let blockerBtnTxt = item.isTbdSelected?"TBD shift selected":'Job Processing';
        var currActivityDate = moment(item.ActivityDate);
        var currDate = moment(new Date());
        var diffDates = currDate.diff(currActivityDate,'days');
        if(isSaving){
            return(
                <Spinner
                    label={saveTxt}
                    ariaLive="assertive"
                    labelPosition="left"
                    size={SpinnerSize.small}/>
            ); 
        }else if(isSaved){
            return(
                <Chip style={{backgroundColor:'black',marginRight:5,cursor:"Pointer"}} 
                    title={actionTxt} label={actionTxt} size="small" color="primary"
                    onDelete={() =>{this.handleSavedChipDelete()}} />
            ); 
        }
        else{
            if(syncData.length>0){
                let syncJobStatus:string[] =  _.map(syncData,'JobStatus');
                if(syncJobStatus.indexOf("ToDelete") !==-1){
                    return (
                        <IconButton
                            iconProps={{iconName:'Blocked'}}
                            style={{color:'black'}}
                            title={'Deleting Outlook Sync'}/>
                    );
                }
                if(syncJobStatus.indexOf("ToInsert") !==-1){
                    return (
                        <IconButton
                            iconProps={{iconName:'Blocked'}}
                            style={{color:'black'}}
                            title={'Creating Outlook Sync'}/>
                    );
                }
                if(syncJobStatus.indexOf("ToUpdate") !==-1){
                    return (
                        <IconButton
                            iconProps={{iconName:'Blocked'}}
                            style={{color:'black'}}
                            title={'Updating Outlook Sync'}/>
                    );
                }
            }
            // validate start date
            if(diffDates>0){
                return (
                    <IconButton
                        iconProps={{iconName:'Blocked'}}
                        style={{color:'black'}}
                        title={'Activity Date is in Past'}/>
                );
            }
            return (
                (
                    <>
                    {item.isTbdSelected?(
                        <IconButton
                            iconProps={{iconName:'Blocked'}}
                            style={{color:'black'}}
                            title={blockerBtnTxt}
                            ariaLabel={blockerBtnTxt}/>
                        ):
                        (<CommandButton
                            split={true}
                            text=""
                            splitButtonAriaLabel="See 2 options"
                            aria-roledescription="split button"
                            menuProps={menuprops}
                            styles={customSplitButtonStyles}                            
                            iconProps={initialIcon}
                            title={title}
                            onClick={()=>this.handleDirectIconClick()}
                            ariaLabel={ariaLabel}
                            className={classNames.menubutton}
                        />)
                    }
                    </>
                )
            )
        }
    }
    handleActSyncSave = (actSyncArr:any[],action:string) =>{
        this.setState({
            showForm:false
        });
        this.props.handleActSyncSave(actSyncArr,action);
    }
    public render() {
        const {Shifts,isPushed,item,syncData}  = this.props;
        const {showForm}  = this.state;
        return (
            <div>
                {this.renderSwitch()}
                <Panel
                    type={PanelType.medium}
                    headerText={"Push to Outlook"}
                    isBlocking={true}
                    isOpen={showForm}
                    hasCloseButton={true}
                    // isHiddenOnDismiss={true}
                    onDismiss={this.handleFormOpenBtnClick}
                    closeButtonAriaLabel="Close">                    
                    {!isPushed? (
                        <NewOutlookForm
                            ActivityDetails = {this.convertActDetails(item)}
                            Shifts = {Shifts}
                            LocationNames={item.LocationName}
                            handleActSyncSave ={this.handleActSyncSave} 
                            handleFormCancelClick={this.handleFormOpenBtnClick}/>
                    ):(
                        <EditOutLookFrom 
                            ActivityDetails = {this.convertActDetails(item)}
                            ActivitySyncData={syncData}
                            handleActSyncSave ={this.handleActSyncSave} 
                            handleFormCancelClick={this.handleFormOpenBtnClick}/>
                    )}
                </Panel>
            </div>
        );
    }
}
